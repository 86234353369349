import React from "react";

import BrushIcon from "@material-ui/icons/Brush";
import CreateIcon from "@material-ui/icons/Create";
import styles from "./drawingOptions.module.scss"

import { SliderPicker } from "react-color";

import Slider from "@material-ui/core/Slider";

import fillIcon from "../../../img/game-mode/drawtogether/fill.png";
import clearBoard from "../../../img/game-mode/drawtogether/delete.png";
import editPaint from "../../../img/game-mode/drawtogether/paint.png";
import iWannaDraw from "../../../img/game-mode/drawtogether/add.png";
import skipDraw from "../../../img/game-mode/drawtogether/remove.png";

const Canvas = ({ socket, handleChange, handleChangeComplete, brushColour, setBrushColour, brushSize, setBrushSize }) => {

  const [showColourPicker, setShowColourPicker] = React.useState(false);

  const [colour] = React.useState([
    "#b71c1c",
    "#d32f2f",
    "#f44336",
    "#e57373",
    "#ffcdd2",
    "#880e4f",
    "#c2185b",
    "#e91e63",
    "#f06292",
    "#f8bbd0",
    "#4a148c",
    "#7b1fa2",
    "#9c27b0",
    "#ba68c8",
    "#e1bee7",
    "#311b92",
    "#512da8",
    "#673ab7",
    "#9575cd",
    "#d1c4e9",
    "#1a237e",
    "#303f9f",
    "#3f51b5",
    "#7986cb",
    "#c5cae9",
    "#0d47a1",
    "#1976d2",
    "#2196f3",
    "#64b5f6",
    "#bbdefb",
    "#01579b",
    "#0288d1",
    "#03a9f4",
    "#4fc3f7",
    "#b3e5fc",
    "#006064",
    "#0097a7",
    "#00bcd4",
    "#4dd0e1",
    "#b2ebf2",
    "#004d40",
    "#00796b",
    "#009688",
    "#4db6ac",
    "#b2dfdb",
    "#194d33",
    "#388e3c",
    "#4caf50",
    "#81c784",
    "#c8e6c9",
    "#33691e",
    "#689f38",
    "#8bc34a",
    "#aed581",
    "#dcedc8",
    "#827717",
    "#afb42b",
    "#cddc39",
    "#dce775",
    "#f0f4c3",
    "#f57f17",
    "#fbc02d",
    "#ffeb3b",
    "#fff176",
    "#fff9c4",
    "#ff6f00",
    "#ffa000",
    "#ffc107",
    "#ffd54f",
    "#ffecb3",
    "#e65100",
    "#f57c00",
    "#ff9800",
    "#ffb74d",
    "#ffe0b2",
    "#bf360c",
    "#e64a19",
    "#ff5722",
    "#ff8a65",
    "#ffccbc",
    "#3e2723",
    "#5d4037",
    "#795548",
    "#a1887f",
    "#d7ccc8",
    "#263238",
    "#455a64",
    "#607d8b",
    "#90a4ae",
    "#cfd8dc",
    "#000000",
    "#525252",
    "#969696",
    "#d9d9d9",
    "#ffffff",
  ]);

  return (
    <section className={styles.drawOptions}>
      {showColourPicker ? (
        <section className={styles.drawingEditScreen}>
          <button
            onClick={() => {
              setShowColourPicker(false);
            }}
            className={styles.drawingEditScreenCloseBtn}
          >
            Close
            </button>
          <section className={styles.BrushSizeSection}>
            <p className={styles.BrushSizeSectionName}>Brush Size</p>
            <section className={styles.BrushSizeSectionValOption}>
              <button
                className={styles.BrushSizeSectionValOptionBtn}
                style={{
                  color: brushColour,
                }}
                onClick={() => setBrushSize(1)}
              >
                <CreateIcon />
              </button>
              <Slider
                value={brushSize}
                onChange={handleChange}
                aria-labelledby="continuous-slider"
              />
              <button
                style={{
                  color: brushColour,
                }}
                className={styles.BrushSizeSectionValOptionBtn}
                onClick={() => setBrushSize(100)}
              >
                <BrushIcon />
              </button>
            </section>
          </section>

          <section className={styles.BrushColourSection}>
            <p className={styles.BrushColourSectionName}>Colour</p>
            <section className={styles.BrushColourSectionPal}>
              <SliderPicker
                color={brushColour}
                onChangeComplete={handleChangeComplete}
                height="500"
              />
              <section className={styles.BrushColourSectionPalHolder}>
                {colour.map((e) => (
                  <button
                    style={{
                      background: e,
                    }}
                    className={styles.BrushColourSectionPalHolderColours}
                    onClick={() => {
                      setBrushColour(e);
                      // handleChangeComplete(e);
                      // setShowColourPicker(false);
                    }}
                  ></button>
                ))}
              </section>
            </section>
          </section>
        </section>
      ) : (
        <></>
      )}

      <button
        className={styles.drawOptionsBtn}

        onClick={() => {
          socket.emit("gameData", { type: "skipDrawing" }, (res) => {

          });
        }}
      >
        <img src={skipDraw} style={{ width: "20px", height: "100%" }} alt="skip" />
      </button>
      <button
        className={styles.drawOptionsBtn}
        onClick={() => {
          socket.emit("gameData", { type: "addToDraw" }, (res) => {

          });
        }}
      >
        <img src={iWannaDraw} style={{ width: "20px", height: "100%" }} alt="draw" />
      </button>
      <button
        className={styles.drawOptionsBtn}
        style={{
          color: brushColour,
        }}
        onClick={(e) => {
          if (showColourPicker) {
            setShowColourPicker(false);
          } else {
            setShowColourPicker(true);
          }
        }}
      >
        {/* <ColorLensIcon /> */}
        <img src={editPaint} style={{ width: "100%", height: "100%" }} alt="edit" />
      </button>
      <button
        className={styles.drawOptionsBtn}
        style={{ backgroundSize: "auto" }}
        onClick={() => {
          socket.emit("gameData", { type: "fill", colour: brushColour }, (res) => {

          });
        }}
      >
        {/* <FormatColorFillIcon /> */}
        <img src={fillIcon} style={{ width: "100%", height: "100%" }} alt="fill" />
      </button>
      <button
        className={styles.drawOptionsBtn}
        onClick={() => {
          socket.emit("gameData", { type: "drawingstatus", value: "clear" }, (res) => {

          });
        }}
      >
        {/* <DeleteForeverIcon /> */}
        <img src={clearBoard} style={{ width: "100%", height: "100%" }} alt="clear" />
      </button>
    </section>
  );
};

export default Canvas;
