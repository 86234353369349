import React from "react";

import { motion } from "framer-motion";
import "./ohNoTableCards.scss";
import rotatebgCard from "../../../img/game-mode/ohno/Cards/rotateb.png";
import stopbgCard from "../../../img/game-mode/ohno/Cards/stopb.png";
import plus2bgCard from "../../../img/game-mode/ohno/Cards/plus2b.png";
import wildFront from "../../../img/game-mode/ohno/Cards/wild.png";

const OhNoCards = ({ socket, userDetails, table }) => {
  const listItem = {
    hidden: { opacity: 0, y: -300, x: -300 },
    show: { opacity: 1, y: 0, x: 0 },
    transition: {
      type: "spring",
      stiffness: 0,
    },
  };

  return table.slice(Math.max(table.length - 2, 0)).map((card) =>
    card.value === "ROTATE" ? (
      <motion.li
        key={card.id}
        variants={listItem}
        whileHover={{ y: 0, scale: 1.2, zIndex: 20 }}
        style={{
          background: card.cc,
          backgroundImage: "url(" + rotatebgCard + ")",
          backgroundSize: "cover",
          boxShadow:
            "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
        }}
        className="ohNoCards-table-middle"
      >
        {/* <img
          src={rotateCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__top-img"
          alt=""
        />
        <img
          src={rotateCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__bot-img"
          alt=""
        /> */}
      </motion.li>
    ) : card.value === "SKIP" ? (
      <motion.li
        key={card.id}
        variants={listItem}
        whileHover={{ y: 0, scale: 1.2, zIndex: 20 }}
        style={{
          background: card.cc,
          backgroundImage: "url(" + stopbgCard + ")",
          backgroundSize: "cover",
          boxShadow:
            "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
        }}
        className="ohNoCards-table-middle"
      >
        {/* <img
          src={skipCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__top-img"
          alt=""
        />
        <img
          src={skipCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__bot-img"
          alt=""
        /> */}
      </motion.li>
    ) : card.value === "+2" ? (
      <motion.li
        key={card.id}
        variants={listItem}
        whileHover={{ y: 0, scale: 1.2, zIndex: 20 }}
        style={{
          background: card.cc,
          backgroundImage: "url(" + plus2bgCard + ")",
          backgroundSize: "cover",
          boxShadow:
            "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
        }}
        className="ohNoCards-table-middle"
      >
        {/* <img
          src={skipCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__top-img"
          alt=""
        />
        <img
          src={skipCard}
          style={{ backgroundColor: card.ccb }}
          className="ohNoCards-table-middle__bot-img"
          alt=""
        /> */}
      </motion.li>
    ) : card.type === "Wild" ? (
      <motion.li
        key={card.id}
        variants={listItem}
        whileHover={{ y: 0, scale: 1.2, zIndex: 20 }}
        style={{
          background: card.cc,
          backgroundImage: "url(" + wildFront + ")",
          backgroundSize: "cover",
          boxShadow:
            "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
        }}
        className="ohNoCards-table-middle"
      >
        <p className="ohNoCards-table-middle__top">{card.value}</p>
        <p className="ohNoCards-table-middle__bot">{card.value}</p>
      </motion.li>
    ) : (
      <motion.li
        key={card.id}
        variants={listItem}
        whileHover={{ y: 0, scale: 1.2, zIndex: 20 }}
        style={{
          background: card.cc,
          boxShadow:
            "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
        }}
        className="ohNoCards-table-middle"
      >
        <p className="ohNoCards-table-middle__top">{card.value}</p>
        <p className="ohNoCards-table-middle__bot">{card.value}</p>
      </motion.li>
    )
  );
};

export default OhNoCards;
