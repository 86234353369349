// Imports
import React from "react";
import "./privacyPage.scss";
import { motion } from "framer-motion";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const PrivacyPage = ({ setShowPrivacy }) => {
  return (
    <section className="privacy-dashboard">
      <motion.button onClick={() => setShowPrivacy(false)} whileHover={{ scale: 1.3, color: "#ff7675" }} className="back-btn">
        <ArrowBackIcon />
      </motion.button>
      <h1 className="policy-heading">Privacy Policy</h1>

      <h6>Information we collect and what do we do with it</h6>
      <p>
        To create an account, you provide us with details regarding your
        username, email address and your password. This registration information
        is stored in a database with encryption when required. This information
        is only used within PocketGames (with all its connected resources) and
        it is not intended to leave its ecosystem. The information will be used
        for authentication when required and to provide you (our user) with the
        correct information when using the site. Your information is not sold to
        any 3rd party users or companies.
      </p>
      <br />
      <p>
        In addition to your registration information, you further have an option
        to provide a profile picture for your user. This image is also stored
        within our database and is directly associated with your account and
        yourself. PocketGames access these images via a pubic domain since the
        images are stored via the cloud. You have every right to remove this
        image by deleting and replacing it with something else. Your previous
        profile image is deleted right away.
      </p>
      <br />
      <p>
        Information regarding your location (country code and IP Address) is
        also collected and sent to the server. This information is used to
        provide a vague location to other users on the website. One example of
        this is, showing a flag of what country you are from. Your IP address is
        not stored in the database only your country code.
      </p>
      <br />
      <p>
        We collect information on how the site is being used. With the help of
        Cookies. Cookies are used to help with authentication and can be deleted
        if you wish. However, on reauthentication, cookies will be created to
        help persist user information. These are not used for the intent to
        collect personal data.
      </p>
      <br />
      <p>Your information will stay within the ecosystem of PocketGames.</p>

      <h6>Your Rights</h6>
      <p>
        You have the right to access, update or change the information you have
        provided to us. Contact us for any information that is not visible
        within PocketGames.
      </p>

      <h6>How long do we keep your data</h6>
      <p>
        The main use for your data is for you to access to PocketGames and to
        provide you with the best experience on the site. We need to keep any
        data that is required to do this. However, you can request your account
        to be deleted. A feature will be added for you to do this within the
        application.
      </p>

      <h6>Your Responsibility</h6>
      <p>
        As a user of PocketGames, you take responsibility for your actions on
        the website. We provide you with a medium to interact with your friends
        (via screen sharing or drawing). It is your responsibility to make sure
        that your actions do not break any laws within the country you reside
        in. It is also your responsibility to make sure you keep up to date with
        the privacy policy. The website is currently in beta and is subject to
        change as we develop it. We will notify any users on the discord channel
        if this policy does change dramatically.
      </p>
      <br />
      <p>
        You take full responsibility for the image you upload and guarantee that
        it does not contain any explicit imagery or wording. Any account that
        does contain any explicit images or wording will have the image removed
        and have their account at risk of being deleted and banned. This image
        is stored within the EU.
      </p>
      <br />
      <p>
        By registering with us, you accept the responsibility stated above and
        accept that your account may be at risk if these are broken.
      </p>
      <h6>Policy Updates</h6>
      <p> Last Reviewed on - 8th July 2020</p>
      <br />
      <p>
        We may update this Policy when necessary to reflect changes we make and
        to make sure we are proving you, our uses, with the most professional
        and upfront information on how we use your data. You can get direct
        notification about these policy updates on our discord.
      </p>
    </section>
  );
};

export default PrivacyPage;
