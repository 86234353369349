import React from "react";
import styles from "./roomLobby.module.scss"

const MatchSaga = ({ socket, userDetails }) => {

  const [currentPlayers, setCurrentPlayers] = React.useState([

  ]);


  React.useEffect(() => {
    socket.emit("gameData", {
      type: "getCurrentUserList",
    }, (res) => {

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    socket.on("getCurrentUserList", function (playerList) {
      setCurrentPlayers(playerList);

      console.log(playerList)
    });

    return () => {
      socket.off("getCurrentUserList")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className={styles.lobbyBoardMain}>
      <section className={styles.playerCardHolder} >
        {currentPlayers.map(player => <section className={styles.playerCard}> <img src={player.avatar} alt="" /> <p>{player.name}</p></section>)}
      </section>
    </section>
  );
};

export default MatchSaga;
