import React from "react";
import styles from "./leaderboard.module.scss";
import leaderboardS from "../../../img/leaderboardS.png";
import Logo2 from "../../../img/Crown.png"

import defaultImage from "../../../img/bg-new-room-card.png"

const Leaderboard = () => {

  const colourList = ["#EAD2A5", "#a5c3ea", "#D7A5EA", "#EEA5AD"]
  const colourLighterList = ["#f0dfc1", "#b7d0f0", "#dcb6eb", "#f1b6bd"]

  const [userData, setUserData] = React.useState({
    "topPlayers": [{}], "trivia": [{}], "drawTogether": [{}]
  });
  const [selectedLeaderboard, setSelectedLeaderboard] = React.useState(0);


  React.useEffect(() => {

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    };

    fetch("/getGlobalLeaderboard", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.staus) {
        } else {
          setUserData(data);
        }
      });

    return () => { };
  }, []);


  const addDefaultSrc = (ev) => {
    ev.target.src = defaultImage
  };

  return (
    <section className={styles.leaderboard}>
      <img className={styles.leaderboardImg} src={leaderboardS} alt="devices" />
      <section className={styles.leaderboardHolder}>
        <section className={styles.leaderboardHolderTopPlayers}>
          <p style={{ fontWeight: 800 }}>Top Players</p>
          {userData.topPlayers.length > 0 ? (
            <section className={styles.leaderboardHolderTopPlayersCards}>
              {userData.topPlayers.map((e, i) => {
                if (e.value) {
                  return (
                    <section key={i} className={styles.topPlayerCards}>
                      <img style={{ width: "50px", zIndex: "3" }} src={Logo2} alt="logo" />
                      <section style={{ backgroundColor: colourList[i] }} className={styles.topPlayerCardsProfile}>
                        <img onError={(e) => addDefaultSrc(e)} style={{ border: `15px solid ${colourLighterList[i]}` }} src={e.avatar} alt="avatar" />
                      </section>

                      <section style={{ backgroundColor: colourList[i] }} className={styles.topPlayerCardsUserDetails}>
                        <p className={styles.topPlayerCardsUserDetailsName} >{e.username}</p>
                        <span className={styles.topPlayerCardsUserDetailsTab} >{e.text}</span>
                        <p className={styles.topPlayerCardsUserDetailsValue} >{e.value}</p>
                      </section>

                    </section>
                  );
                }
                else {
                  return <></>
                }
              })}
            </section>
          ) : (<></>)}
        </section>
      </section>
      <section className={styles.leaderboardTable}>
        <section className={styles.meassageHeader}>
          <p>Leaderboard</p>
          <section className={styles.meassageHeaderSelectBoard}>
            <button style={{ opacity: selectedLeaderboard === 0 ? 1 : 0.5 }} onClick={() => setSelectedLeaderboard(0)}>Draw Together</button>
            <button style={{ opacity: selectedLeaderboard === 1 ? 1 : 0.5 }} onClick={() => setSelectedLeaderboard(1)}>Trivia</button>
            <button style={{ opacity: selectedLeaderboard === 2 ? 1 : 0.5 }} onClick={() => setSelectedLeaderboard(2)}>Match Saga</button>
          </section>

        </section>


        {selectedLeaderboard === 1 ? (
          <section className={styles.leaderboardTableValueHeader}>
            <p>Avatar</p>
            <p>Name</p>
            <p>Score</p>
            <p>Wins</p>
            <p>Played</p>
          </section>
        ) : selectedLeaderboard === 0 ? (
          <section className={styles.leaderboardTableValueHeader}>
            <p>Avatar</p>
            <p>Name</p>
            <p>Score</p>
            <p>Wins</p>
            <p>Played</p>
          </section>
        ) : selectedLeaderboard === 2 ? (
          <section className={styles.leaderboardTableValueHeader}>
            <p>Avatar</p>
            <p>Name</p>
            <p>High Score</p>
            <p>Total Matches</p>
            <p>Total Buffs</p>
          </section>
        ) : <></>}


        {selectedLeaderboard === 1 ? (
          <section className={styles.leaderboardTableValueHolder}>
            {userData.trivia.map((e, i) => {
              if (e.scores) {
                return (
                  <section key={i} className={styles.leaderboardTableValue}>
                    <img onError={(e) => addDefaultSrc(e)} style={{ border: "3px solid #453e72" }} src={e.avatar} alt="avatar" />
                    <p>{e.username}</p>
                    <p>{e.scores.trivia ? e.scores.trivia.score : 0}</p>
                    <p>{e.scores.trivia ? e.scores.trivia.wins : 0}</p>
                    <p>{e.scores.trivia ? e.scores.trivia.played : 0}</p>
                  </section>
                );
              }
              else {
                return <></>
              }
            })}
          </section>
        ) : selectedLeaderboard === 0 ? (
          <section className={styles.leaderboardTableValueHolder}>
            {userData.drawTogether.map((e, i) => {
              if (e.scores) {
                return (
                  <section key={i} className={styles.leaderboardTableValue}>
                    <img onError={(e) => addDefaultSrc(e)} style={{ border: "3px solid #ffeaa7" }} src={e.avatar} alt="avatar" />
                    <p>{e.username}</p>
                    <p>{e.scores.drawTogether ? e.scores.drawTogether.score : 0}</p>
                    <p>{e.scores.drawTogether ? e.scores.drawTogether.wins : 0}</p>
                    <p>{e.scores.drawTogether ? e.scores.drawTogether.played : 0}</p>
                  </section>
                );
              }
              else {
                return <></>
              }
            })}
          </section>
        ) : selectedLeaderboard === 2 ? (
          <section className={styles.leaderboardTableValueHolder}>
            {userData.matchSaga.map((e, i) => {
              if (e.scores) {
                return (
                  <section key={i} className={styles.leaderboardTableValue}>
                    <img onError={(e) => addDefaultSrc(e)} style={{ border: "3px solid #453e72" }} src={e.avatar} alt="avatar" />
                    <p>{e.username}</p>
                    <p>{e.scores.matchSaga ? e.scores.matchSaga.soloScore : 0}</p>
                    <p>{e.scores.matchSaga ? e.scores.matchSaga.solomatches : 0}</p>
                    <p>{e.scores.matchSaga ? e.scores.matchSaga.solopowerUps : 0}</p>
                  </section>
                );
              }
              else {
                return <></>
              }
            })}
          </section>
        ) : <></>}
      </section>
    </section>
  );
};

export default Leaderboard;