import React from "react";
import useSound from "use-sound";
import Cell from "./Cell";
import BuffCell from "./BuffCells";
import GameOverlay from "./gameOverlay";

//Sounds
import correctSound from "../../../../audio/SwapGame/correctSound.wav";
import correctFastSound from "../../../../audio/SwapGame/correctSound2.wav";
import errorSound from "../../../../audio/SwapGame/error.wav";

const BoardView = ({ currentPlayers, socket }) => {
  //Audio
  const [correctFastSoundPlay] = useSound(correctSound, { volume: 0.2 });
  const [correctSoundPlay] = useSound(correctFastSound, { volume: 0.2 });
  const [errorSoundPlay] = useSound(errorSound, { volume: 0.2 });

  //States
  const [selectedCells, setSelectedCells] = React.useState([]);
  const [buffProgress, setBuffProgress] = React.useState(0);

  const [validMove, setValidMove] = React.useState(true);

  const [selected, setSelected] = React.useState({});
  const [firstSelected, setFirstSelected] = React.useState({});

  const [buffs, setBuffs] = React.useState([]);
  const [boardCells, setBoardCells] = React.useState([]);

  // New Required States
  const [timer, setTimer] = React.useState(0);
  const [maxTime, setMaxtime] = React.useState(60);
  const [scoreDetails, setScoreDetails] = React.useState({
    score: 0,
    highScore: 0,
    matchesMade: 0,
    powerUps: 0,
  });
  const [shuffleCount, setShuffleCount] = React.useState(0);

  const [lastMoveResults, setLastMoveResults] = React.useState({
    speed: true,
    valid: true,
    temp: true,
  });

  React.useEffect(() => {
    socket.emit(
      "gameData",
      {
        type: "getCurrentSoloBoard",
      },
      (res) => {}
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (lastMoveResults.speed) {
      correctFastSoundPlay();
    } else if (lastMoveResults.valid) {
      correctSoundPlay();
    } else if (!lastMoveResults.valid) {
      errorSoundPlay();
    }

    setValidMove(lastMoveResults.valid);

    setTimeout(() => {
      setValidMove(true);
    }, 500);
  }, [lastMoveResults, correctFastSoundPlay, correctSoundPlay, errorSoundPlay]);

  React.useEffect(() => {
    socket.on("getCurrentSoloBoardOnly", function (data) {
      setBoardCells(data.board);
    });

    socket.on("shuffleCount", function (data) {
      setShuffleCount(data.shuffleCount);
    });

    socket.on("getCurrentSoloBoard", function (data) {
      setBoardCells(data.board);
      setBuffProgress(data.buffScore);

      setLastMoveResults((e) => ({
        speed: data.speed,
        valid: data.valid,
        temp: !e.temp,
      }));
    });

    socket.on("gameTimer", function (data) {
      setTimer(data.currentTimer);
      setMaxtime(data.maxTimer);
    });

    socket.on("buffCells", function (data) {
      setBuffs(data.buffs);
    });

    socket.on("gameDetials", function (data) {
      setScoreDetails({
        score: data.score,
        highScore: data.highScore,
        matchesMade: data.matchesMade,
        powerUps: data.powerUps,
      });
    });

    socket.on("gameScore", function (data) {
      setScoreDetails((e) => ({ ...e, score: data.score }));
    });

    return () => {
      socket.off("getCurrentSoloBoard");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (selectedCells.length === 2) {
      if (
        selectedCells[0].row === selectedCells[1].row &&
        selectedCells[0].col === selectedCells[1].col
      ) {
        setFirstSelected({});
        setSelectedCells([]);

        return;
      }

      socket.emit(
        "gameData",
        {
          type: "swapSoloBoardElements",
          swapElements: selectedCells,
        },
        (res) => {}
      );

      setFirstSelected({});
      setSelectedCells([]);
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCells]);

  const startGame = () => {
    socket.emit(
      "gameData",
      {
        type: "startSoloGame",
      },
      (res) => {}
    );
  };

  const activateShuffle = () => {
    socket.emit(
      "gameData",
      {
        type: "shuffleBoard",
      },
      (res) => {
        setShuffleCount(res.shuffleCount);
        setBoardCells(res.board);
      }
    );
  };

  const activateBuff = (index) => {
    socket.emit(
      "gameData",
      {
        type: "activateBuff",
        index: index,
      },
      (res) => {}
    );
  };

  return (
    <div>
      <section className="swap-details-box">
        {shuffleCount !== null && shuffleCount > 0 ? (
          <div
            style={{ background: "#ffeaa7", color: "#cfa65a" }}
            className="swap-reset-button"
            onClick={() => {
              activateShuffle();
            }}
          >
            {`${shuffleCount} Shuffle(s)`}
          </div>
        ) : (
          <div
            style={{ background: "#fab1a0", color: "#d63031" }}
            className="swap-reset-button"
            onClick={() => {}}
          >
            {`${shuffleCount} Shuffle(s)`}
          </div>
        )}
        <p
          style={{ color: "White", fontWeight: "bold" }}
          className="swap-score-box swap-current-score"
        >
          {scoreDetails.score}
        </p>

        <p
          style={{
            background: "#fdcb6e7c",
            fontSize: "1rem",
            color: "White",
            fontWeight: "bold",
          }}
          className="swap-score-box"
        >
          {scoreDetails.highScore}
        </p>
      </section>

      <div class="swap-timer-container">
        <div
          style={{ width: `${(timer / maxTime) * 100}%` }}
          class="swap-timer-container-bar swap-timer-container-bar-o"
        ></div>
      </div>

      <section className="swap-board-holder">
        <section className="swap-user-list">
          {currentPlayers.map((player) => (
            <section className="swap-user-list-cards">
              <img
                src={player.avatar}
                className="swap-user-list-cards-img"
                alt="Profile"
              />
              <p
                style={{ overflow: "hidden" }}
                className="swap-user-list-cards-name"
              >
                {player.name}
              </p>

              <p
                style={{ marginTop: "10px" }}
                className="swap-user-list-cards-score"
              >
                {player.soloScore}
              </p>
            </section>
          ))}
        </section>
        <div
          style={{
            // border: !validMove ? "2px solid #ff7675" : "none",
            background: !validMove ? "#ff757557" : "#2a314d",
          }}
          className="swap-board"
        >
          {boardCells !== [] ? (
            boardCells.map((row, rowIndex) => {
              return (
                <div>
                  {row.map((col, colIndex) => {
                    return (
                      <Cell
                        key={rowIndex * boardCells.length + colIndex}
                        index={{
                          col: colIndex,
                          row: rowIndex,
                          id: rowIndex * boardCells.length + colIndex,
                        }}
                        colour={col.colour}
                        selectedCells={selectedCells}
                        setSelected={setSelected}
                        selected={selected}
                        setSelectedCells={setSelectedCells}
                        ability={col.ability}
                        image={col.image}
                        setFirstSelecte={setFirstSelected}
                        firstSelected={firstSelected}
                      />
                    );
                  })}
                </div>
              );
            })
          ) : (
            <></>
          )}
          {timer <= 0 ? (
            <GameOverlay scoreDetails={scoreDetails} startGame={startGame} />
          ) : (
            <></>
          )}
        </div>
        <section className="swap-buff-details">
          <section className="swap-buff-bar-desktop">
            <div
              style={{ height: `${buffProgress.toString()}%` }}
              className="swap-buff-bar"
            ></div>
          </section>

          <section className="swap-buff-bar-phone">
            <div
              style={{ width: `${buffProgress.toString()}%` }}
              className="swap-buff-bar"
            ></div>
          </section>

          <section className="swap-buff-list-holder">
            {buffs.map((col, colIndex) => {
              return (
                <BuffCell
                  key={colIndex}
                  id={colIndex}
                  buff={col}
                  setBuffs={setBuffs}
                  activateBuff={activateBuff}
                />
              );
            })}
          </section>
        </section>
      </section>
    </div>
  );
};

export default BoardView;
