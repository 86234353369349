import React from "react";
import Cookies from "js-cookie";

import bgdrawingCard from "../../img/bg-draw-card.png";

import styles from "./createProfile.module.scss";

const CreateProfile = ({ details }) => {
  const [nameFieldValue, setNameFieldValue] = React.useState("");
  const [randomAvatar, setRandomAvatar] = React.useState("");
  const [usernameError, setUsernameError] = React.useState([""]);

  const handleNameValueChange = (value) => {
    setNameFieldValue(value.target.value);
  };

  const chooseRandomAvatar = () => {
    const avatarList = [
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar1.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar2.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar3.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar4.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar5.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar6.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar7.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar8.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar9.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar10.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar11.png",
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar12.png",
    ];

    const ranNumber = Math.floor(Math.random() * avatarList.length - 1 + 1);
    setRandomAvatar(avatarList[ranNumber]);
  };

  const resetImage = () => {
    setRandomAvatar("");
  };

  const update = async () => {
    const token = Cookies.get("token");
    let chosenImage = "";
    if (token) {
      if (randomAvatar !== "") {
        chosenImage = randomAvatar;
      } else {
        chosenImage = details.avatar;
      }

      if (!chosenImage || chosenImage === "") {
        console.log("error uploading");
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        credentials: "same-origin",
        body: JSON.stringify({
          username: nameFieldValue,
          image: chosenImage,
        }),
      };

      fetch("/changeUsername", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.errors) {
            setUsernameError(data.errors);
            console.log(data.errors);
          } else {
            Cookies.remove("token");
            Cookies.set("token", data.token, { expires: 1 });
            window.location.reload(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const logout = () => {
    Cookies.remove("token");
    window.location.reload(false);
  };

  return (
    <section className={styles.cpBackdrop}>
      <section
        style={{ backgroundImage: `url(${bgdrawingCard})`, opacity: "0.2" }}
      ></section>
      <section className={styles.cpBackdropHolder}>
        <section className={styles.cpBackdropHolderProfile}>
          <h1 className={styles.cpBackdropHolderTitle}>Welcome</h1>

          {randomAvatar !== "" ? (
            <img
              className={styles.cpBackdropHolderAvatar}
              src={randomAvatar}
              alt="avatar"
            />
          ) : (
            <img
              className={styles.cpBackdropHolderAvatar}
              src={details.avatar}
              alt="avatar"
            />
          )}

          <section className={styles.buttonSet}>
            <button
              className={styles.buttonSetProfileChange}
              onClick={() => {
                resetImage();
              }}
            >
              Original
            </button>

            <button
              className={styles.buttonSetProfileChange}
              onClick={() => {
                chooseRandomAvatar();
              }}
            >
              Randomise
            </button>
          </section>
        </section>
        <section className={styles.cpBackdropHolderUsername}>
          <p>Choose a Username</p>
          {usernameError.map((e) => (
            <span className={styles.usernameError}>{e}</span>
          ))}
          <input
            className={styles.cpBackdropHolderTfield}
            placeholder="Username"
            onChange={handleNameValueChange}
            value={nameFieldValue}
          />
        </section>

        <section className={styles.cpBackdropHolderUsername}>
          <section className={styles.buttonSet}>
            <button
              style={{ background: "#CA7E71" }}
              className={styles.buttonSetMainB}
              onClick={() => {
                logout();
              }}
            >
              Logout
            </button>
            <button
              style={{ background: "#71CA7D" }}
              className={styles.buttonSetMainB}
              onClick={() => {
                update();
              }}
            >
              Continue
            </button>
          </section>
        </section>
      </section>
    </section>
  );
};

export default CreateProfile;
