import React from "react";
import Cookies from "js-cookie";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { motion } from "framer-motion";

import styles from "./login.module.scss";

import Leaderboard from "../components/leaderboard/Leaderboard"
import PrivacyPage from "./PrivacyPage"

import deviceImage from "../../img/device-showcase.png";
import Logo from "../../img/general/Logo.png"

const Login = ({ setAuth, auth, setIsAppInstalled }) => {

  const [showPrivacy, setShowPrivacy] = React.useState(false)
  const [showGDPR, setShowGDPR] = React.useState(false)

  React.useEffect(() => {
    readAcceptCookies();
    readCookie();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptCookies = () => {
    Cookies.set("acceptCookies", true, { expires: 2147483647 });

    setShowGDPR(false)
  }

  const readAcceptCookies = () => {
    const token = Cookies.get("acceptCookies");
    if (token) {
      setShowGDPR(false)
    }
    else {
      setShowGDPR(true)
    }
  }

  const readCookie = () => {
    const token = Cookies.get("token");
    if (token) {
      // Authenticate the token?
      // Attempt to connect to the socket server
      // Maybe set token true and call the dashboard screen?
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "same-origin",
        body: JSON.stringify({
          token: token,
        }),
      };

      fetch("/token", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.success === true) {
            setAuth(true);
            return;
          }
          Cookies.remove("token");
          setAuth(false);
        });
    } else {
      setAuth(false);
    }
  };

  const responseFacebook = (response) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        accessToken: response.accessToken,
        userID: response.userID,
      }),
    };

    fetch("/loginFacebook", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          Cookies.set("token", data.token, { expires: 1 });
          setAuth(true);
          // window.location.reload(false);
        }
      })
      .catch((e) => {
        console.log("Error while Auth");
        console.log(e);
      });
  };

  const responseSucGoogle = (response) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "same-origin",
      body: JSON.stringify({
        tokenId: response.tokenId,
      }),
    };

    fetch("/googleLogin", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          Cookies.set("token", data.token, { expires: 1 });
          setAuth(true);
        }

        // setRegErrors(data.status);
      })
      .catch((e) => {
        console.log("Error while Auth");
        console.log(e);
      });
  };

  const responseErrGoogle = (response) => {
    console.log(response);
  };

  if (showPrivacy)
    return (
      <PrivacyPage setShowPrivacy={setShowPrivacy} />
    )
  else {

    return (
      <section className={styles.loginSection} >
        {showGDPR ? (
          <section className={styles.infoBoxHolder}>
            <section className={styles.infoBoxHolderBox}>
              <p className={styles.infoBoxHolderBoxH}>Cookies and Privacy</p>
              <p className={styles.infoBoxHolderBoxM}>We use cookies necessary for the basic functionality of this website. By continuing to browse, you consent to the use of cookies. We do NOT use cookies for advertisement.</p>
              <section>
                <motion.button onClick={acceptCookies} whileHover={{ scale: 1.1 }} className={styles.infoBoxHolderBoxAcceptBtn}>Accept</motion.button>
                <motion.button whileHover={{ scale: 1.1 }} className={styles.infoBoxHolderBoxPrivacyBtn} onClick={() => setShowPrivacy(true)}>Privacy</motion.button>
              </section>
            </section>
          </section>) : <></>}
        <section className={styles.loginSideBar}>
          <section className={styles.brand}>
            <img src={Logo} alt="Logo" />
            <p>PocketGames.io</p>
          </section>

          <p className={styles.topMessage}>Play together with your friends or meet new people!</p>

          <h1 className={styles.wordCarousel}>
            <div>
              <ul className={styles.flip3}>
                <li>Chat</li>
                <li>Guess Drawings</li>
                <li>Play Trivia</li>
              </ul>
            </div>
            <p className={styles.wordCarouselEnd}>Together</p>
          </h1>

          <br />

          <p style={{ width: "80%" }} className={styles.topMessage}>Enjoy a better exprience by downloading the website as a webapp.</p>

          <br />

          <section className={styles.loginOptions}>
            <FacebookLogin
              appId="578589392829369"
              autoLoad={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <motion.button
                  className="social-login__facebook-login"
                  whileHover={{
                    scale: 1.05,
                  }}
                  onClick={renderProps.onClick}
                  style={{ backgroundColor: "#3260A2" }}
                >
                  Login with Facebook
                </motion.button>
              )}
            />
            <GoogleLogin
              clientId="373396131470-d9vibss8und01tvkmjr4sd6obgkn24ne.apps.googleusercontent.com"
              buttonText="Login"
              icon={true}
              onSuccess={responseSucGoogle}
              onFailure={responseErrGoogle}
              cookiePolicy={"single_host_origin"}
              render={(renderProps) => (
                <motion.button
                  icon={true}
                  whileHover={{
                    scale: 1.05,
                  }}
                  className="social-login__google-login"
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}

                  style={{ backgroundColor: "#E6856C" }}
                >
                  Login with Google
                </motion.button>
              )}
            />
            <br />
            <p className={styles.topMessage}>Guest accounts will not save stats!</p>
            <motion.button whileHover={{
              scale: 1.05,
            }} onClick={() => {
              setAuth(true)
            }} style={{ backgroundColor: "#779E5E" }}>Continue as Guest</motion.button>
          </section>

          <img className={styles.showcaseImage} style={{ width: "40%" }} src={deviceImage} alt="devices" />
          <button className={styles.privacyBtn} onClick={() => setShowPrivacy(true)}>Privacy</button>
        </section>

        <Leaderboard />
      </section >
    );
  }
};

export default Login;
