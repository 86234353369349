import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
// Other Classes!
// import Dashboard from "./dashboard/Dashboard";
import Dashboard from "./pages/dashboard"

import Login from "./pages/login/Login";
import PWAWarning from "./pages/login/PWAWarning";

import PoolBoard from "./pages/joinedRoom/LudoSaga"

import {
  BrowserRouter as Router,
} from "react-router-dom";

const App = () => {
  const [auth, setAuth] = React.useState(false);
  const [isAppInstalled, setIsAppInstalled] = React.useState(true);

  React.useEffect(() => {
    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if (isMobile) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        setIsAppInstalled(true);
      } else {
        // setIsAppInstalled(false);
      }
    } else {
      setIsAppInstalled(true);
    }
  }, []);

  return (
    // <PoolBoard />
    <Router>
      <section>
        <ToastContainer limit={4} />
        {isAppInstalled === true ? (
          <section>
            {auth ? (
              <Dashboard authToken={auth} setAuth={setAuth} />
            ) : (


              <Login
                auth={auth}
                setAuth={setAuth}
                setIsAppInstalled={setIsAppInstalled}
              />
            )}
          </section>
        ) : (
          <PWAWarning setIsAppInstalled={setIsAppInstalled} />
        )}
      </section>
    </Router>
  );
};

export default App;
