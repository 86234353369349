import React from "react";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import HelpIcon from "@material-ui/icons/Help";

import RotateBoard from "../../../../img/game-mode/swaps/Buff-Icons/RotateBoard.png";
import ExtraPoints from "../../../../img/game-mode/swaps/Buff-Icons/ExtraPoints.png";
import ExplodeBomb from "../../../../img/game-mode/swaps/Buff-Icons/ExplodeBomb.png";
import ExtraTime from "../../../../img/game-mode/swaps/Buff-Icons/ExtraTime.png";
import RandomBuffs from "../../../../img/game-mode/swaps/Buff-Icons/RandomBuffs.png";

const GameOverlay = ({ scoreDetails, startGame }) => {
  const [currentScreen, setCurrentScreen] = React.useState(0);

  if (currentScreen === 0)
    return (
      <section className="swap-overLay">
        {/* <h1 className="stroke">Swaps</h1> */}

        <section className="swap-stats-holder">
          <section>
            <p
              style={{
                fontSize: "0.6rem",
                color: "White",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              Score
            </p>
            <p
              style={{
                fontSize: "2rem",
                background: "#d375ff73",
                color: "White",
                fontWeight: "bold",
              }}
              className="swap-score-box"
            >
              {scoreDetails.score}
            </p>
          </section>

          <section>
            <p
              style={{
                fontSize: "0.6rem",
                color: "White",

                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              Matches
            </p>
            <p
              style={{
                fontSize: "2rem",
                color: "White",
                background: "#55efc37c",
                fontWeight: "bold",
              }}
              className="swap-score-box"
            >
              {scoreDetails.matchesMade}
            </p>
          </section>

          <section>
            <p
              style={{
                fontSize: "0.6rem",
                color: "White",
                fontWeight: "bold",

                textAlign: "center",
                marginBottom: "5px",
              }}
            >
              PowerUps
            </p>
            <p
              style={{
                fontSize: "2rem",
                background: "#ff757573",
                color: "White",
                fontWeight: "bold",
              }}
              className="swap-score-box"
            >
              {scoreDetails.powerUps}
            </p>
          </section>
        </section>

        {/* <button className="startButton" onClick={() => startGame()}>
        Start
      </button> */}
        <button className="swap-play-button" onClick={() => startGame()}>
          <PlayCircleOutlineIcon style={{ fontSize: "8rem" }} />
        </button>

        <HelpIcon
          onClick={() => setCurrentScreen(1)}
          className="swap-help-icon"
        />
      </section>
    );

  if (currentScreen === 1)
    return (
      <section className="swap-overLay-info">
        <button className="swap-close-info" onClick={() => setCurrentScreen(0)}>
          Close
        </button>
        <h1>How to play</h1>
        <p className="swap-overLay-info-text">
          Connect matching of 3 or more in a row or column. The quicker you do
          it the more buffs and points you will collect.
        </p>
        <p className="swap-overLay-info-text">
          The right side will have the buffs for you to activate when you want.
        </p>
        <p className="swap-overLay-info-text">
          Everyone gets 2 free shuffles in case there is nothing to connect on
          the board, so use it wisely.
        </p>
        <p className="swap-overLay-info-text">Red Outlines - Bombs</p>
        <p className="swap-overLay-info-text">Blue Outlines - More Points</p>
        <br />
        <h1>Buffs</h1>

        <section className="swap-overLay-info-image-text">
          <img src={ExplodeBomb} alt="buff" />
          <p>
            Explodes all the bombs on the screen - These can be seen outlined in
            red
          </p>
        </section>
        <section className="swap-overLay-info-image-text">
          <img src={RotateBoard} alt="buff" />
          <p>Randomise the board</p>
        </section>
        <section className="swap-overLay-info-image-text">
          <img src={ExtraPoints} alt="buff" />
          <p>For a few seconds you will gain extra points</p>
        </section>
        <section className="swap-overLay-info-image-text">
          <img src={ExtraTime} alt="buff" />
          <p>Adds some extra time to your game</p>
        </section>
        <section className="swap-overLay-info-image-text">
          <img src={RandomBuffs} alt="buff" />
          <p>Add more buffs to your board</p>
        </section>
      </section>
    );
};

export default GameOverlay;
