import React from "react";
import styles from "./roomCard.module.scss";

const RoomCard = ({ roomList, item, getRoomData, joinRoom }) => {
  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  return (
    <button
      onClick={() => {
        if (roomList[item].password) {
          getRoomData(roomList[item].name);
        } else {
          joinRoom(roomList[item].name);
        }
      }}
      className={styles.roomCardMain}
    >

      <img
        onError={(e) => addDefaultSrc(e)}
        src={roomList[item].avatar}
        alt="player"
        className={styles.roomCardMainAvatar}
      />

      <section
        className={styles.roomCardMainRoomDetails}
      >
        <ul className={styles.roomCardMainUserList}>
          {roomList[item].userList.map((item) => {
            return (
              <li>
                <img
                  onError={(e) => addDefaultSrc(e)}
                  src={item.avatar}
                  alt="player"
                />
              </li>
            );
          })}
        </ul>

        <p className={styles.roomCardMainRoomDetailsOwner}>{roomList[item].name}</p>
        <p className={styles.roomCardMainRoomDetailsRoomTitle}>{roomList[item].title}</p>

        <section className={styles.roomCardMainTagList}>
          <span
            style={{
              background: roomList[item].password
                ? "rgb(189, 83, 83)"
                : "rgb(35, 145, 196)",
            }}
            className={styles.roomCardMainTagListTag}
          >
            {roomList[item].password ? "Locked" : "Public"}
          </span>
          <span
            style={{
              background:
                roomList[item].modeType === "DrawRoom"
                  ? "rgb(92, 180, 99)"
                  : roomList[item].modeType === "TriviaRoom"
                    ? "rgb(245, 204, 92)"
                    : "rgb(35, 145, 196)",
            }}
            className={styles.roomCardMainTagListTag}
          >
            {roomList[item].modeType}
          </span>
        </section>
      </section>
    </button>
  );
};

export default RoomCard;