import React from "react";
import { motion } from "framer-motion";

import RotateBoard from "../../../../img/game-mode/swaps/Buff-Icons/RotateBoard.png";
import ExtraPoints from "../../../../img/game-mode/swaps/Buff-Icons/ExtraPoints.png";
import ExplodeBomb from "../../../../img/game-mode/swaps/Buff-Icons/ExplodeBomb.png";
import ExtraTime from "../../../../img/game-mode/swaps/Buff-Icons/ExtraTime.png";
import RandomBuffs from "../../../../img/game-mode/swaps/Buff-Icons/RandomBuffs.png";

const getImage = (colourCode) => {
  switch (colourCode) {
    case "FreeRefresh":
      return RotateBoard;

    case "MultiplyPoints":
      return ExtraPoints;

    case "ExplodeBombs":
      return ExplodeBomb;

    case "MoreTime":
      return ExtraTime;

    case "RandomiseBuffs":
      return RandomBuffs;

    default:
      return "";
  }
};

const Cell = ({ id, activateBuff, buff, setBuffs }) => {
  return (
    <motion.img
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", duration: 0.5 }}
      exit={{ scale: 0 }}
      alt=""
      src={getImage(buff.code)}
      onClick={() => {
        activateBuff(id)
      }}
      style={{
        padding: "10px",
      }}
      className={`swap-buff-cell`}
    />
  );
};

export default Cell;
