import React from "react";
import styles from "./publicRooms.module.scss";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { motion } from "framer-motion";
import UpdateIcon from "@material-ui/icons/Update";
import RoomCard from "../components/roomCard";
import Leaderboard from "../components/leaderboard";
import TvIcon from '@material-ui/icons/Tv';
import Logo from "../../img/general/Logo2.png"

import bgdrawingCard from "../../img/bg-draw-card.png";
import bgTriviaCard from "../../img/game-cards/bg-trivia-card.png";

import bgOhNoCard from "../../img/game-mode/ohno/bgOhNoCard.png";

import swaps from "../../img/game-mode/swaps/cover.png"

const PublicRooms = ({
  socket,
  userDetails,
  setUserDetails,
  setSelectedRoomType,
  joinRoom,
  setSelectedRoomDetails
}) => {
  const [roomList, setRoomList] = React.useState([])
  const [currentDisplay, setCurrentDisplay] = React.useState(0);
  const [background, setBackground] = React.useState(bgdrawingCard);

  React.useEffect(() => {
    socket.on("roomList", (data) => {
      setRoomList(data.rooms);
    });

    return () => {
      socket.off('roomList');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.emit("roomList");
  }, [socket]);

  const roomUpdatedList = () => {
    socket.emit("roomList");
  };

  const getRoomData = (roomOwner) => {
    setSelectedRoomDetails({ roomOwner: roomOwner });
    setSelectedRoomType(-1);
  };

  return (
    <>
      <section
        id="container"
        style={{

          backgroundImage: `url(${background})`,

          backgroundSize: "200px 200px",
        }}
        className={styles.backgroundArtCover}
      />

      <section className={styles.container}>
        <section className={styles.modeSelectorHolder}>
          <motion.section className={styles.modeSelector} exit={{ y: -1000 }}>
            <section className={styles.modeSelectorCardsHolder}>
              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring", stiffness: 100
                }}
              >
                <img
                  className={styles.modeSelectorCardBackground}
                  onMouseOver={() => setBackground(bgdrawingCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-lobby.png"
                  alt="draw"
                  onClick={(e) => {
                    setSelectedRoomType(1);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Room lobby</p>
              </motion.section>

              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring", stiffness: 100
                }}
              >
                <img
                  className={styles.modeSelectorCardBackground}
                  onMouseOver={() => setBackground(bgdrawingCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-drawTogether.png"
                  alt="draw"
                  onClick={(e) => {
                    setSelectedRoomType(2);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Draw Together</p>
              </motion.section>

              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  delay: 0.1, type: "spring", stiffness: 100
                }}
              >
                <img
                  onMouseOver={() => setBackground(bgTriviaCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  className={styles.modeSelectorCardBackground}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-trivia.png"
                  alt="watch"
                  onClick={(e) => {
                    setSelectedRoomType(3);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Trivia</p>
              </motion.section>

              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  delay: 0.2, type: "spring", stiffness: 100
                }}
              >
                <img
                  onMouseOver={() => setBackground(bgOhNoCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  className={styles.modeSelectorCardBackground}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-ohNo.png"
                  alt="watch"
                  onClick={(e) => {
                    setSelectedRoomType(4);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Oh-No</p>
              </motion.section>
              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  delay: 0.2, type: "spring", stiffness: 100
                }}
              >
                <img
                  onMouseOver={() => setBackground(swaps)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  className={styles.modeSelectorCardBackground}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-matchSaga.png"
                  alt="watch"
                  onClick={(e) => {
                    setSelectedRoomType(5);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Match Saga</p>
              </motion.section>
              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring", stiffness: 100
                }}
              >
                <img
                  className={styles.modeSelectorCardBackground}
                  onMouseOver={() => setBackground(bgdrawingCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-watchTogether.png"
                  alt="draw"
                  onClick={(e) => {
                    setSelectedRoomType(6);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Watch Together</p>
              </motion.section>
              <motion.section
                className={styles.modeSelectorCard}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring", stiffness: 100
                }}
              >
                <img
                  className={styles.modeSelectorCardBackground}
                  onMouseOver={() => setBackground(bgdrawingCard)}
                  // onMouseOut={() => seta("../../../img/background-2.png")}
                  src="https://localpocketimagesave.s3.eu-west-1.amazonaws.com/mode-ludoSaga.png"
                  alt="draw"
                  onClick={(e) => {
                    setSelectedRoomType(7);
                  }}
                />
                <p className={styles.modeSelectorCardName}>Ludo Saga</p>
              </motion.section>
            </section>
          </motion.section>

          <section className={styles.lowerSection}>
            <section
              className={styles.menuTopBar}
            >
              <motion.button
                style={{ opacity: currentDisplay === 1 ? 1 : 0.5, background: currentDisplay === 1 ? "white" : "rgba(68, 68, 68, 0.411)", color: currentDisplay === 1 ? "orange" : "black" }}
                onClick={() => setCurrentDisplay(1)}
                className={styles.optionButtons}
                initial={{ background: "white" }}
                whileTap={{ scale: currentDisplay === 1 ? 1 : 0.8 }}
              >

                <EqualizerIcon />
              </motion.button>
              <motion.button
                style={{ background: currentDisplay === 0 ? "white" : "rgba(68, 68, 68, 0.411)", color: currentDisplay === 0 ? "purple" : "black" }}
                onClick={() => setCurrentDisplay(0)}
                className={styles.optionButtons}
                whileTap={{ scale: currentDisplay === 0 ? 1 : 0.8 }}
              >
                <TvIcon />
              </motion.button>
              <p style={{ background: currentDisplay === 0 ? "white" : "rgba(68, 68, 68, 0.411)", color: currentDisplay === 0 ? "purple" : "black" }}
                onClick={() => setCurrentDisplay(0)}
                className={styles.optionButtons}>{roomList.length}/25</p>
              <motion.button
                style={{ background: "green", opacity: 0.1 }}
                onClick={() => roomUpdatedList()}
                className={styles.optionButtons}

                initial={{ background: "green", opacity: 0.5 }}
                whileHover={{ opacity: 1 }}
                whileTap={{ scale: 0.8 }}
              >
                <UpdateIcon />
              </motion.button>
            </section>
            {currentDisplay === 1 ? (
              <Leaderboard />
            ) : (
              <section
                className={styles.roomCard}

              >
                <ul className={styles.roomCardRooms}>
                  {Object.keys(roomList).map((item, i) => {
                    return (
                      <RoomCard
                        key={roomList[item].name}
                        socket={socket}
                        joinRoom={joinRoom}
                        roomList={roomList}
                        item={item}
                        getRoomData={getRoomData}
                      />
                    );
                  })}
                </ul>

                {roomList.length === 0 ? (
                  <section
                    className={styles.noRooms}
                  >
                    <img src={Logo} />
                    <br />
                    <span className={styles.noRoomsTop}>Looks like there are currently no rooms here.</span>
                    <br />
                    <span className={styles.noRoomsLower}>Create your own room from the options above and invite your friends.</span>
                  </section>
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </section>
            )}
          </section>
        </section>
      </section>
    </>
  );
};

export default PublicRooms;
