import React from "react";
import styles from "./friends.module.scss";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { toast } from 'react-toastify';

const Friends = ({ Cookies, socket, userDetails }) => {
  const [friends, setFriends] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [usersListByName, setUsersListByName] = React.useState([]);
  const [viewPending, setViewPending] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreeClose = (username) => {
    rejectFriendRequest(username);
    setOpen(false);
  };

  React.useEffect(() => {
    socket.on("getAllUsersList", (data) => {
      setUsersListByName(data.userList.map((e) => e.name));
    });

    return () => {
      socket.off("getAllUsersList");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.emit("getAllUsersList");
    return () => {
      socket.off("getAllUsersList");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // <ReactCountryFlag className="flag" countryCode={e.countryCode} />;

  //Get friends

  const getFriendsFromDatabase = () => {
    const token = Cookies.get("token");
    if (token) {
      // Authenticate the token?
      // Attempt to connect to the socket server
      // Maybe set token true and call the dashboard screen?
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        credentials: "same-origin",
      };

      fetch("/getFriends", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data.staus) {
          } else {
            setFriends(data.friends[0].friends);
            setPending(data.pendingRequest[0].friends);
          }
        });
    }
  };
  React.useEffect(() => {
    getFriendsFromDatabase();
    return () => { };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rejectFriendRequest = (name) => {
    // Reject socket
    socket.emit("rejectFriend", { name: name }, (response) => {
      if (response) {
        getFriendsFromDatabase();
      }
    });
  };
  const acceptFriendRequest = (name) => {
    // Reject socket
    socket.emit("acceptFriend", { name: name }, (response) => {
      if (response) {
        getFriendsFromDatabase();
      }
    });
  };

  const selectProfile = (name) => {
    socket.emit("getUserProfile", { name: name }, (response) => {
   
      if (response.status === true) {
        // Friends
      } else {
        // Not Friends
      }
    });
  };

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  return (
    userDetails.guest !== true ? (
      <section className={styles.friends}>
        <button
          style={{
            background: viewPending ? "orange" : "rgba(112, 170, 235, 0)",
          }}
          className={styles.pendingFriends}
          onClick={() => setViewPending(!viewPending)}
        >
          {/* Pending */}
          <Badge badgeContent={pending.length} color="primary">
            <ControlPointDuplicateIcon style={{ color: "white" }} />
          </Badge>
        </button>


        {viewPending ? (
          pending.length > 0 ? (
            <section className={styles.friendsHolder}>
              <p className="dim-title">Pending Requests</p>
              {pending.map((details) => (
                <section className={styles.friendOnlineCard} key={details.username}>

                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={details.avatar}
                    alt="avatar"
                  />
                  <section className={styles.onlineFriendsDetails}>
                    <p>{details.username}</p>
                    <section className={styles.onlineIcons}>
                      <button
                        className={styles.friendCardAccept}
                        onClick={() => acceptFriendRequest(details.username)}
                      >
                        <CheckCircleIcon />
                      </button>
                      <button
                        className={styles.friendCardDelete}
                        onClick={() => rejectFriendRequest(details.username)}
                      >
                        <DeleteForeverIcon />
                      </button>
                    </section>

                  </section>
                </section>
              ))}
            </section>
          ) : (
            <section>
              <p className="dim-title">No Pending Requests</p>
            </section>
          )
        ) : (
          <section className={styles.friendsHolder}>
            <p className="dim-title">Online</p>
            {friends
              .filter((e) => usersListByName.includes(e.username))
              .map((details) => (
                <section onClick={() => selectProfile(details.username)} key={details.username} className={styles.friendOnlineCard}>
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={details.avatar}
                    alt="avatar"
                  />
                  <section className="online-friends-details">
                    <p>{details.username}</p>
                  </section>
                </section>
              ))}
            <p className="dim-title">Offline</p>
            {friends
              .filter((e) => !usersListByName.includes(e.username))
              .map((details) => (
                <section
                  key={details.username}
                  onClick={() => toast.warning("Cannot view offline users", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  })}
                  className={styles.friendOfflineCard}
                >
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={details.avatar}
                    alt="avatar"
                  />
                  <p>{details.username}</p>
                  <button
                    className="friend-card__delete"
                    onClick={handleClickOpen}
                  >
                    <DeleteForeverIcon />
                  </button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Remove {details.username}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Are you sure you want to remove {details.username} from
                        your friends list?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="Secondary">
                        No
                      </Button>
                      <Button
                        onClick={() => handleAgreeClose(details.username)}
                        color="primary"
                      >
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </section>
              ))}
          </section>
        )}


      </section >
    ) : (<section className={styles.friends}><p>Not Available for Guest Users</p></section>)
  );
};
export default Friends;
