import React from "react";
import Emoji from "react-emoji-render";
import SendIcon from "@material-ui/icons/Send";
import "./chat.scss";
import { toast } from 'react-toastify';
import logo from "../../../img/general/LogoSpaced.png";
import useRecorder from "./util/useRecorder";
import { AudioPlayer } from "./util/audioPlayer"
import MicIcon from '@material-ui/icons/Mic';
import DeleteIcon from '@material-ui/icons/Delete';
const Chat = ({
  socket, userDetails, chatMessageList, muteList,
  setMuteList
}) => {
  const [isMyInputFocused, setIsMyInputFocused] = React.useState(false);
  const [chatTextField, setChatTextField] = React.useState("");
  const [lockChat, setLockChat] = React.useState(false);

  const inputEl = React.useRef(null);

  let [audioRaw, audioURL, isRecording, startRecording, stopRecording, removeData] = useRecorder();




  const handleChatFieldChange = (value) => {
    // setChatTextField(parseEmojis(value.target.value));
    setChatTextField(value.target.value);
  };

  const messagesEndRef = React.createRef();

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };

  React.useEffect(() => {
    scrollToBottom()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessageList, isMyInputFocused]);

  //Sending message
  const sendMessage = () => {
    if ((chatTextField.toUpperCase().split(" ")[0] === "/MSG" ||
      chatTextField.toUpperCase().split(" ")[0] === "/MESSAGE") &&
      userDetails.guest === true) {
      toast.warning("Please log in to message other users", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    } else if (chatTextField !== "") {
      socket.emit("createMessage", {
        text: chatTextField,
        audio: audioRaw,
      }, (res) => {
        console.log(res)
      });
    } else if (audioRaw !== "") {
      socket.emit("createMessage", {
        text: chatTextField,
        audio: audioRaw,
      }, (res) => {
        console.log(res)
      });
    }

    setChatTextField("");
    removeData();
  };

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/Logo.png";
  };

  return (
    <section
      style={{
        maxHeight: isMyInputFocused ? "500px" : "150px",
      }}
      className="message-section"
    >
      <button style={{ background: lockChat ? "#ff7675" : "#b2bec3", opacity: lockChat ? 1 : 0.5 }} className="minChat" onClick={() => setLockChat(e => !e)}></button>
      <ul>
        {chatMessageList.map(({ from, fromAvatar, audio, message, toAvatar, type, to }, i) => {
          if (from === "Server") {
            return (
              <li key={from + i} className="message-section__box">
                <img
                  onError={(e) => addDefaultSrc(e)}
                  className="chat-image"
                  src={logo}
                  alt={from}
                />
                <section className="player-message">
                  <p className="player-message__server">{from}</p>
                  <p className="player-message__serverm">{message}</p>
                </section>
              </li>
            );
          } else if (type === "Audio" && audio !== "") {
            console.log("audio")
            console.log(audio)

            const blob = new Blob([audio], { type: "audio/wav" });
            const url = URL.createObjectURL(blob)
            // return <audio src={url} controls />
            // return <AudioPlayer audioURL={url} />

            return (
              <li key={from + i} className="message-section__box">

                <img
                  onError={(e) => addDefaultSrc(e)}
                  className="chat-image"
                  src={fromAvatar}
                  alt="player"
                />

                <section className="player-message">
                  <p
                    onClick={() => {
                      setChatTextField(`/msg ${from} `);
                      inputEl.current.focus();
                    }}
                    className="player-message__sender"
                  >
                    {from}
                  </p>
                  <p className="player-message__message">
                    <AudioPlayer audioURL={url} />
                  </p>
                </section>
              </li>
            );
          } else if (type === "Private") {
            if (from === userDetails.name) {
              return (
                <li key={from + i} className="message-section__private__own">
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    className="chat-image"
                    src={fromAvatar}
                    alt="player"
                  />
                  <section className="player-message">
                    <p className="player-message__sender">
                      {<p>{`Me ⟶ ${to}`}</p>}
                    </p>
                    <p className="player-message__message">
                      <Emoji text={message} />
                    </p>
                  </section>
                </li>
              );
            } else {
              return (
                <li key={from + i} className="message-section__private">
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    className="chat-image"
                    src={fromAvatar}
                    alt="player"
                  />
                  <section className="player-message">
                    <p
                      onClick={() => {
                        setChatTextField(`/msg ${from} `);
                        inputEl.current.focus();
                      }}
                      className="player-message__sender"
                    >
                      {<p>{`${from} ⟶ Me`}</p>}
                    </p>
                    <p className="player-message__message">
                      <Emoji text={message} />
                    </p>
                  </section>
                </li>
              );
            }
          } else {
            if (!muteList.includes(from)) {
              return (
                <li key={from + i} className="message-section__box">

                  <img
                    onError={(e) => addDefaultSrc(e)}
                    className="chat-image"
                    src={fromAvatar}
                    alt="player"
                  />

                  <section className="player-message">
                    <p
                      onClick={() => {
                        setChatTextField(`/msg ${from} `);
                        inputEl.current.focus();
                      }}
                      className="player-message__sender"
                    >
                      {from}
                    </p>
                    <p className="player-message__message">
                      <Emoji text={message} />
                    </p>
                  </section>
                </li>
              );
            }
            else {
              return (<></>)
            }
          }
        })}
        <div ref={messagesEndRef} />
      </ul>
      <footer className="message-section__footer">
        <button className="message-section__footer__audioBtn" style={{ backgroundColor: isRecording ? "#f57788" : "#fab0a06f" }} onClick={isRecording ? stopRecording : startRecording}>
          <MicIcon style={{ fontSize: "1rem" }} />
        </button>
        {audioRaw !== "" ? (<section className="audioPreviewHolder"> <DeleteIcon onClick={removeData} /><AudioPlayer audioURL={audioURL} /> </section>) :
          <input
            onFocus={() => !lockChat ? setIsMyInputFocused(true) : null}
            onBlur={() => !lockChat ? setIsMyInputFocused(false) : null}
            ref={inputEl}
            className="message_input"
            placeholder="Type a message"
            value={chatTextField}
            onChange={(e) => handleChatFieldChange(e)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                sendMessage();
                ev.preventDefault();
              }
            }}
          />
        }
        <button className="message-section__footer__sendBtn" onClick={() => sendMessage()}>
          <SendIcon style={{ fontSize: "0.9rem" }} />
        </button>

      </footer>
    </section>
  );
};
export default Chat;
