import React from "react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import goldCoin from "../../../../img/game-mode/trivia/gold.png";
import silverCoin from "../../../../img/game-mode/trivia/silver.png";

import styles from "./ownProfile.module.scss";

import logo from "../../../../img/Crown.png"

const axios = require("axios").default;

const MenuProfile = ({ Cookies, socket, userDetails }) => {
  // const [state, setState] = React.useState({
  //   blockPrivateMessage: false,
  //   safeChat: false,
  //   hideLeaderboard: false,
  // });

  const [image, setImage] = React.useState("");
  const [settings, setSettings] = React.useState({ blockPrivateMessagesOn: false, chatFilterOn: false, hideInLeaderboard: false });
  const [currentScreen, setCurrentScreen] = React.useState(0);
  const [coins, setCoins] = React.useState({ gems: 0, shards: 0 });

  const handleChange = (event) => {

    socket.emit("handleSettingChange", { setting: event.target.name, value: event.target.checked }, (response) => {
      if (response) {

        console.log(response.settings)
        // toast.error(response.error, {
        //   position: "top-left",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      }
    });

    setSettings({ ...settings, [event.target.name]: event.target.checked });
  };

  // Need to get from server ..
  const preMadeAvatars = [
    // { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar1.png", link: "1" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar2.png", link: "2" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar3.png", link: "3" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar4.png", link: "4" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar5.png", link: "5" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar6.png", link: "6" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar7.png", link: "7" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar8.png", link: "8" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar9.png", link: "9" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar10.png", link: "10" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar11.png", link: "11" },
    { avatar: "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Avatar12.png", link: "12" },
  ]

  const disconnectUser = (req) => {
    socket.disconnect();
    Cookies.remove("token");
    // history.push(`/login`);
    window.location.reload(false);
  };

  const [status, setStatus] = React.useState("");

  const choosePremadeAvatars = (e) => {
    const token = Cookies.get("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        image: e.link,
      }),
    };

    fetch("/changeDefaultAvatar", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const token = Cookies.get("token");

        if (data.error === false) {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
            credentials: "same-origin",
          };

          fetch("/getProfile", requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                setImage({ display: data.avatar });
                setCurrentScreen(0)
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const uploadAvatar = (e) => {
    const token = Cookies.get("token");
    if (token) {
      // Authenticate the token?
      // Attempt to connect to the socket server
      // Maybe set token true and call the dashboard screen?
      if (!e.target.files[0]) {
        return;
      }

      // if (e.target.files[0].size > 20000) {
      //   alert("File is too big!");
      // } else {
      const data = new FormData();
      data.append("name", e.target.files[0].name);
      data.append("file", e.target.files[0]);

      axios.post("/changeavatar", data).then((res) => {
        if (res.data.status === "Success") {
          setImage({ display: res.data.avatar });
          setStatus(
            res.data.status +
            " : Please reload the page to get your updated picture"
          );
          setCurrentScreen(0)
        } else {
          setStatus(res.data.status + " : Please try again");
        }
      });
      // }
    }
  };

  React.useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      // Authenticate the token?
      // Attempt to connect to the socket server
      // Maybe set token true and call the dashboard screen?
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        credentials: "same-origin",
      };

      fetch("/getProfile", requestOptions)
        .then((res) => res.json())
        .then((data) => {
          if (data) {
            setImage({ display: data.avatar });
            setCoins({ gems: data.gems, shards: data.shards })
          }
        });
    }
    return () => { };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  React.useEffect(() => {
    socket.emit("getUserSettings", (response) => {
      if (response) {

        console.log(response.settings)

        setSettings({ blockPrivateMessagesOn: response.settings.blockPrivateMessagesOn, chatFilterOn: response.settings.chatFilterOn, hideInLeaderboard: response.settings.hideInLeaderboard });

        // toast.error(response.error, {
        //   position: "top-left",
        //   autoClose: 3000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      }
    });
  }, [])

  // const UpdateSetting = (name) => {
  //   socket.emit("banPlayer", { name: name }, (response) => {
  //     if (response) {

  //       // toast.error(response.error, {
  //       //   position: "top-left",
  //       //   autoClose: 3000,
  //       //   hideProgressBar: false,
  //       //   closeOnClick: true,
  //       //   pauseOnHover: true,
  //       //   draggable: true,
  //       //   progress: undefined,
  //       // });
  //     }
  //   });
  // };

  return (
    <section className={styles.profile}>
      <section className={styles.profileMainOptions}>
        <section onClick={() => {
          if (userDetails.guest === false) {
            setCurrentScreen(1);
          }
        }}

          style={{ opacity: userDetails.guest !== true ? 1 : 0.2 }}
          className={styles.profileMainOptionsAvatar}><CameraAltIcon /></section>
        <img className={styles.profileImage} onError={(e) => addDefaultSrc(e)}
          src={userDetails.guest === true ? "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png" : image.display}
          alt={userDetails.name} />
        <section onClick={(e) => {
          disconnectUser(e);
        }} className={styles.profileMainOptionsLogOut}><ExitToAppIcon /></section>
      </section>

      <section className={styles.profileStats}>
        <section className={styles.profileStatsSingle}>
          <p className={styles.profileStatsSingleH}>Friends</p>
          <p className={styles.profileStatsSingleM}>-</p>
        </section>
        <section className={styles.profileStatsSingle}>
          <p className={styles.profileStatsSingleH}>Wins</p>
          <p className={styles.profileStatsSingleM}>-</p>
        </section>
        <section className={styles.profileStatsSingle}>
          <p className={styles.profileStatsSingleH}>Played</p>
          <p className={styles.profileStatsSingleM}>-</p>
        </section>
      </section>
      <p className={styles.profileName}>{userDetails.name}</p>

      {userDetails.guest !== true ? (
        <>
          <br />
          <section className={styles.profileCoins}>
            <img className={styles.profileInvBadgeImg} src={goldCoin} alt="logo" />
            <p>{coins.shards}</p>
          </section>

          <section className={styles.profileCoins}>
            <img className={styles.profileInvBadgeImg} src={silverCoin} alt="logo" />
            <p>{coins.gems}</p>
          </section>

          <br />
          <p>Inventory</p>
          <section className={styles.profileInv}>
            <img className={styles.profileInvBadgeImg} src={logo} alt="logo" />
          </section>
          <br />

          <p>Options</p>
          <section className={styles.profileOptions}>
            <button className={styles.profileOptionsBtn}>Name Colour</button>
            <button className={styles.profileOptionsBtn}>Border Colour</button>
          </section>

          <br />

          <p>Settings</p>
          <FormControl style={{ color: "rgba(255, 255, 255, 0.205)" }} component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={settings.blockPrivateMessagesOn} onChange={handleChange} name="blockPrivateMessagesOn" />}
                label="Block Private Message"
              />
              <FormControlLabel
                control={<Switch checked={settings.chatFilterOn} onChange={handleChange} name="chatFilterOn" />}
                label="Safe Chat Filter"
              />
              <FormControlLabel
                control={<Switch checked={settings.hideInLeaderboard} onChange={handleChange} name="hideInLeaderboard" />}
                label="Hide In Leaderboard"
              />
            </FormGroup>
          </FormControl>
          <br />
          {/* <section className={styles.profileImportantOptions}>
        <p className={styles.profileImportantOptionsTip}>THIS CANNOT BE UNDONE</p>
        <button className={styles.profileImportantOptionsBtn}>Delete Account</button>
      </section> */}
        </>
      ) : <></>}


      {
        currentScreen === 1 ? (
          <section className={styles.avatarSelect}>
            <section style={{ color: "red" }} onClick={() => {

              setCurrentScreen(0);
            }}
              className={styles.profileMainOptionsBack}> <HighlightOffIcon /></section>

            <section className={styles.uploadOption}>
              {status !== "" ? <p className="status">{status}</p> : <></>}
              <input
                id="upload-photo"
                type="file"
                title=" "
                style={{ visibility: "hidden" }}
                accept="image/*"
                multiple={false}
                onChange={(e) => {
                  uploadAvatar(e);
                }}
              />
              <label for="upload-photo">Upload</label>
            </section>
            <p className="avatar-text">Click an avatar below</p>
            <section className={styles.defaultAvatars}>
              {preMadeAvatars.map((e) => {
                return (
                  <img
                    onClick={() => choosePremadeAvatars(e)}
                    key={e.link}
                    src={e.avatar}
                    alt={e.avatar}
                  />
                );
              })}
            </section>
          </section>
        ) : (<></>)
      }
    </section >
  );
};
export default MenuProfile;
