// Imports
import React from "react";

import iosOne from "../../img/install-ios-1.jpg";
import iosTwo from "../../img/install-ios-2.jpg";

// Import : CSS
import "./pwaWarning.scss";

const PWAWarning = ({ setIsAppInstalled }) => {
  const [isAndroid, setIsAndroid] = React.useState(false);

  React.useEffect(() => {
    var isMobileAndroid = /Android/i.test(navigator.userAgent);

    if (isMobileAndroid) {
      /* your code here */
      setIsAndroid(true);
    }

    // var isMobileAndroid = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    // if (isMobile) {
    //   /* your code here */
    // }
  }, []);

  return (
    <section className="scrollable">
      <section className="main-pwa-holder">
        <h2 className="main-pwa-holder__title">ADD TO HOMESCREEN</h2>
        <p className="pwa-messages">
          It is advised that you add this website to your homescreen like your
          other apps.
        </p>
        <p className="pwa-messages">
          Some Elements WILL NOT work or be visible on your devices browser
          version if you continue!
        </p>
        <button
          className="main-pwa-holder__warning"
          onClick={() => {
            setIsAppInstalled(true);
          }}
        >
          IGNORE WARNING
        </button>
        {!isAndroid ? (
          <section className="install-holder">
            <h3>How to install (IOS - Safari)</h3>
            <p className="pwa-steps">Step 1</p>
            <img className="install-image" src={iosOne} alt="ios" />
            <p className="pwa-steps">Step 2</p>
            <img className="install-image" src={iosTwo} alt="ios2" />
          </section>
        ) : (
          <section className="install-holder">
            <h3>How to install (Android - Chrome)</h3>
            <p className="pwa-messages">Tap Add to home screen when prompted</p>
          </section>
        )}
      </section>
    </section>
  );
};

export default PWAWarning;
