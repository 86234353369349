import React from "react";

import CanvasScreen from "./CanvasScreen";
import TriviaScreen from "./TriviaScreen";
import OhNo from "./Oh-No";
import MatchSaga from "./MatchSaga"
import RoomLobby from "./RoomLobby"
import ScreenShare from "./ScreenSharing"
import LudoSaga from "./LudoSaga"

const GameScreen = ({ socket, userDetails, setUserDetails }) => {
  // const [roomSettings, setRoomSettings] = React.useState({
  //   roomStatus: true,
  //   roomTags: "",
  //   title: "",
  //   roomOwner: "",
  //   broadcasting: "",
  //   userList: [],
  // });

  const [gameMode, setGameMode] = React.useState("");

  // Get room details - including broadcaster details
  React.useEffect(() => {
    socket.emit("getRoom", userDetails.room, (response) => {
      // setRoomSettings(response.data);
      setGameMode(response.data.currentMode);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.on("roomClosed", (response) => {
      setUserDetails({
        socketID: userDetails.socketID,
        name: userDetails.name,
        room: "Lobby",
        status: userDetails.room,
        guest: userDetails.guest
      });
    });

    socket.on("roomChange", (response) => {
      // setRoomSettings(response.data);
      setGameMode(response.mode);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      {gameMode === 1 ? (
        <RoomLobby socket={socket} userDetails={userDetails} />
      ) : gameMode === 2 ? (
        <CanvasScreen socket={socket} userDetails={userDetails} />
      ) : gameMode === 3 ? (
        <TriviaScreen socket={socket} userDetails={userDetails} />
      ) : gameMode === 4 ? (
        <OhNo socket={socket} userDetails={userDetails} />
      ) : gameMode === 5 ? (
        <MatchSaga socket={socket} userDetails={userDetails} />
      ) : gameMode === 6 ? (
        <ScreenShare socket={socket} userDetails={userDetails} />
      ) : gameMode === 7 ? (
        <LudoSaga socket={socket} userDetails={userDetails} />
      ) : (
        <></>
      )}
    </section>
  );
};

export default GameScreen;
