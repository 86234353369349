import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import styles from "./ohNo.module.scss";

import cardBack from "../../../img/game-mode/ohno/Card-back.png";
import bgOhNoCard from "../../../img/game-mode/ohno/Artboard1.png";
import botImage from "../../../img/game-mode/ohno/bot-profile.png";

import Cards from "./OhNoCards";
import TableCards from "./OhNoTableCards";

const OhNo = ({ socket, userDetails }) => {
  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  const [users, setUsers] = React.useState([]);
  const [screenMessage, setScreenMessage] = useState("");
  const [currentSelector, setCurrentSelector] = React.useState("User5");
  const [hand, setHand] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [table, setTable] = React.useState([
    { type: "Normal", cc: "#020202", colour: "", value: "" },
    { type: "Normal", cc: "#000000", colour: "", value: "" },
  ]);
  const [queuedPlayers, setQueuedPlayers] = React.useState([
    {
      avatar: "",
      name: "User1",
      wins: "",
    },
  ]);
  const [playingPlayers, setPlayingPlayers] = React.useState([
    {
      avatar: "",
      name: "User1",
      wins: "",
    },
  ]);
  const [currentScreen, setCurrentScreen] = React.useState(0);
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const [showWinnerScreen, setShowWinnerScreen] = React.useState(false);
  const [winner, setWinner] = React.useState({ name: "", avatar: "" });


  useEffect(() => {
    socket.emit("gameData", {
      type: "getInitalUserData",
    }, (res) => {

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const addPlayerToPlaying = (playerName) => {
    socket.emit("gameData", {
      type: "addPlayerToPlaying",
      playerName: playerName,
    }, (res) => {

    });
  };

  const removePlayerFromPlaying = (playerName) => {
    socket.emit("gameData", {
      type: "removePlayerFromPlaying",
      playerName: playerName,
    }, (res) => {

    });
  };

  const startGame = () => {
    socket.emit("gameData", {
      type: "startGame",
    }, (res) => {

    });
  };

  const drawCard = () => {
    socket.emit("gameData", {
      type: "drawCard",
    }, (res) => {

    });
  };

  useEffect(() => {
    socket.on("getCurrentUserList", function (cards) {
      setQueuedPlayers(cards);
    });

    socket.on("winner", function (winner) {
      setWinner({ name: winner.name, avatar: winner.avatar });
      setShowWinnerScreen(true);
    });

    socket.on("screenMessage", function (message) {
      setScreenMessage(message);
      showNotification();
    });

    socket.on("getSelectedPlayers", function (users) {
      setPlayingPlayers(users);
    });

    socket.on("userHand", function (cards) {

      setHand(cards);
    });

    socket.on("playingUserDetails", function (users) {

      setUsers(users);
    });

    socket.on("table", function (tableCard) {

      setTable((e) => [...e, ...tableCard]);

    });
    
    socket.on("currentPlayer", function (user) {

      setCurrentSelector(user);
    });

    socket.on("screenUpdate", function (screen) {
      setCurrentScreen(screen);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showNotification = () => {
    // You can use redux for this.
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 1000);
  };

  return (
    <>
      <section
        className={styles.menuBackground}
        id="container"
        style={{
          // background: "white",
          backgroundImage: `url(${bgOhNoCard})`,
          opacity: "0.2",
          backgroundSize: "cover",

          filter: "blur(10px)",
        }}
      />

      <section className={styles.ohNoDashboard}>
        <p className={show ? `${styles.popup} ${styles.show}` : styles.hide}>{screenMessage}</p>
        {showWinnerScreen ? (
          <section className={styles.ohNoWinner}>
            <button
              onClick={() => setShowWinnerScreen(false)}
              className={styles.ohNoWinnerClose}
            >
              Close
            </button>
            <p className={styles.ohNoWinnerWinner}>WINNER</p>
            <img
              className={styles.ohNoWinnerPImage}

              onError={(e) => addDefaultSrc(e)}
              src={winner.avatar}
              alt="winner"
            />
            <p className={styles.ohNoWinnerUsername}>{winner.name}</p>
          </section>
        ) : (
          <></>
        )}
        {currentScreen === 0 ? (
          <section className={styles.ohNoMainMenu}>
            <section className={styles.mainButtonHolder}>
              <button
                className={styles.addBotBtn}
                onClick={() =>
                  socket.emit("gameData", {
                    type: "addABot",
                  }, (res) => {

                  })
                }
              >
                Add a Bot
              </button>
              <button
                className={styles.startGame}
                onClick={() => startGame()}
              >
                Start Game
              </button>
            </section>
            <p
              style={{
                margin: "10px",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
              }}
            >
              Selected
            </p>
            <ul style={{ overflow: "scroll" }} className={styles.ohNoMainMenuPlayerList}>
              {playingPlayers.map((player) => (
                <li className={styles.ohNoMainMenuPlayerListHolder}>
                  <img
                    onError={(e) => addDefaultSrc(e)}

                    src={player.name[0] === "@" ? botImage : player.avatar}
                    alt={player.name}
                    onClick={() => removePlayerFromPlaying(player.name)}
                  />
                  <p >
                    {player.name}
                  </p>
                  <p >
                    {player.wins}
                  </p>
                </li>
              ))}
            </ul>
            <br />
            <p
              style={{
                margin: "10px",
                fontWeight: "bold",
                fontSize: "1rem",
                color: "white",
              }}
            >
              Users Currently Not Playing
            </p>

            <ul className={styles.ohNoMainMenuPlayerList}>
              {queuedPlayers.map((player) => (
                <li className={styles.ohNoMainMenuPlayerListHolder}>
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={player.avatar}
                    alt={player.name}
                    onClick={() => addPlayerToPlaying(player.name)}
                  />
                  <p>
                    {player.name}
                  </p>
                  <p >
                    {player.wins}
                  </p>
                </li>
              ))}
            </ul>
          </section>

        ) : (
          <section className={styles.ohNoGame}>
            <section className={styles.ohNoGameProfiles}>
              {users.map((user) => (
                <section
                  key={user.id}
                  className={styles.ohNoGameProfilesTop}
                >
                  <p
                    className={styles.ohNoGameProfilesTopName}
                    style={{
                      background: "black",
                    }}
                  >
                    {user.name}
                  </p>
                  <img
                    onError={(e) => addDefaultSrc(e)}
                    src={user.name[0] === "@" ? botImage : user.avatar}
                    alt="player"
                  />


                  <p style={{
                    background:
                      currentSelector === user.name ? "#00b894" : "black",
                    border: user.numberOfCards < 2 ? "1px solid red" : "1px solid white",
                  }} className={styles.ohNoNumberHolderNumber}>
                    {user.numberOfCards}
                  </p>

                </section>
              ))}
            </section>
            <section className={styles.ohNoGameDeck}>
              <motion.img
                onClick={() => {
                  drawCard();
                }}
                style={{
                  backgroundImage: "url(" + cardBack + ")",
                  backgroundSize: "cover",
                }}
                whileHover={{ y: 0, scale: 1.2, zIndex: 10 }}
                className={styles.ohNoGameDeckPickSection}
              />

              <motion.ul variants={container} initial="hidden" animate="show">
                <TableCards
                  socket={socket}
                  userDetails={userDetails}
                  table={table}
                  setHand={setHand}
                  setTable={setTable}
                />
              </motion.ul>
            </section>
            <section className={styles.ohNoGameHand}>
              <Cards
                socket={socket}
                userDetails={userDetails}
                hand={hand}
                setHand={setHand}
                setTable={setTable}
              />
            </section>
          </section>
        )}
      </section>
    </>
  );
};

export default OhNo;
