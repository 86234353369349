import React from "react";
import styles from "./screenSharing.module.scss"
import ReactPlayer from 'react-player'
import { motion } from "framer-motion"
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import { toast } from "react-toastify";

const MatchSaga = ({ socket, userDetails }) => {
  const player = React.useRef(null);


  const [url, setUrl] = React.useState();
  const [pip, setPip] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [controls, setControls] = React.useState(false);
  const [light, setLight] = React.useState(false);
  const [volume, setVolume] = React.useState(0.8);
  const [muted, setMuted] = React.useState(false);
  const [played, setPlayed] = React.useState(0);
  const [loaded, setLoaded] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [playbackRate, setPlaybackRate] = React.useState(1.0);
  const [loop, setLoop] = React.useState(false);
  const [seeking, setSeeking] = React.useState(false);
  const [urlNewValue, setUrlNewValue] = React.useState("");

  const [progress, setProgress] = React.useState(0);

  const [showController, setShowController] = React.useState(true);


  const handleURLValueChange = (value) => {
    setUrlNewValue(value.target.value);
  };


  const load = url => {
    setUrl(url)
    setPlayed(0)
    setLoaded(0)
    setPlaying(false)
    setProgress(0)
    setPip(false)
  }

  React.useEffect(() => {
    socket.emit("gameData", {
      type: "getCurrentURL",
    }, (res) => {
      // setUrl(res.url)

      load(res.url)
      toast.dark("Some users may struggle loading the video in time, so you may want to rewind after everyone has loaded so youre all in sync!", {
        position: "top-left",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  }, [0])

  const sendNewUrl = (url) => {
    socket.emit("gameData", {
      type: "newURL",
      url: url
    }, (res) => {

    });
  }

  const getCurrentTime = () => {
    socket.emit("gameData", {
      type: "getCurrentTime",
    }, (res) => {

    });
  }

  const playPauseEmit = () => {
    socket.emit("gameData", {
      type: "playPause",
      value: !playing
    }, (res) => {

    });
  };

  const seekEmit = (value) => {
    socket.emit("gameData", {
      type: "seek",
      value: value
    }, (res) => {

    });
  };


  React.useEffect(() => {
    socket.on("playPause", function (data) {
      handlePlayPause(data.value)
    });

    socket.on("newURL", function (data) {
      console.log(data.url)
      load(data.url)
      // setUrl(data.url)
    });

    socket.on("timeRequest", function (data) {
      socket.emit("gameData", {
        type: "sendCurrentTime",
        user: data.user,
        time: progress.playedSeconds
      }, (res) => {

      });
    });

    socket.on("seek", function (data) {
      if (data.value !== null || !player.current) {
        player.current.seekTo(data.value)
      }
    });

  }, [])

  const handlePlayPause = (value) => {
    setPlaying(value)
  }

  const handleToggleLoop = () => {


    setLoop(e => !e)
  }

  const handleVolumeChange = e => {

    setVolume(parseFloat(e.target.value))
  }

  const handlePlay = () => {
    setPlaying(true)
  }

  const handleEnablePIP = () => {
    setPip(true)
  }

  const handleDisablePIP = () => {
    setPip(false)
  }

  const handlePause = () => {
    setPlaying(false)
  }

  const handleSeekMouseDown = e => {
    setSeeking(true)
  }

  const handleSeekChange = e => {
    setPlayed(parseFloat(e.target.value))
  }

  const handleSeekMouseUp = e => {
    setSeeking(false)
    seekEmit(parseFloat(e.target.value))
    // player.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = state => {
    if (seeking) {
      setSeeking(state)
    }

    setProgress(state)
  }

  const handleEnded = () => {
    setPlaying(loop)
  }

  const handleDuration = (duration) => {
    setDuration(duration)
  }

  return (
    <>
      <div className={styles.playerWrapper}>
        <ReactPlayer
          ref={player}
          className={styles.reactPlayer}
          width='100%'
          height='100vh'
          url={url}
          pip={pip}
          playing={playing}
          controls={controls}
          light={light}
          loop={loop}
          playbackRate={playbackRate}
          volume={volume}
          muted={muted}
          onReady={() => getCurrentTime()}
          // onStart={() => console.log('onStart')}
          onPlay={handlePlay}
          onEnablePIP={handleEnablePIP}
          onDisablePIP={handleDisablePIP}
          onPause={handlePause}
          // onBuffer={() => console.log('onBuffer')}
          // onSeek={e => console.log('onSeek', e)}
          onEnded={handleEnded}
          // onError={e => console.log('onError', e)}
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </div>
      <motion.section onTap={() => { console.log("asd"); setShowController(e => !e) }} className={styles.preventClick}></motion.section>
      <motion.section style={{ display: showController ? "inline" : "none" }} className={styles.controlsHolder}>
        <section className={styles.VolumeControls}>
          <button className={styles.VolumeControlsVolumeButton} onClick={() => setVolume(volume => volume > 0 ? 0 : 0.5)}> {volume > 0 ? <VolumeUpIcon /> : <VolumeOffIcon />}</button>
          {/* <input className={styles.controlsVolumeMute} id='muted' type='checkbox' checked={muted} onChange={handleToggleMuted} /> */}
          <input className={styles.VolumeControlsVolumeSlider} type='range' min={0} max={1} step='any' value={volume} onChange={handleVolumeChange} />
        </section>
        <section className={styles.urlInputHolder}>
          <input
            className={styles.urlInput}
            placeholder={"Enter a URL"}
            type=""
            value={urlNewValue}
            onChange={handleURLValueChange}
          />

          <button
            type="button"
            onClick={() => {
              sendNewUrl(urlNewValue)
              setUrlNewValue("")
            }
            }
            className={styles.urlInputSubmit}
          >
            <span>Add</span>
          </button>
        </section>
        <section className={styles.controls}>
          <button className={styles.controlsPlayButton} onClick={playPauseEmit}>{playing ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}</button>
          <input
            className={styles.controlsSeekSlider}
            type='range' min={0} max={0.999999} step='any'
            value={!seeking ? progress.playedSeconds / duration : played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
          {/* {progress.playedSeconds}
          -
          {duration} */}
        </section>
      </motion.section>
    </>
  );
};

export default MatchSaga;
