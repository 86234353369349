import React from "react";
// import "./winnerScreen.scss";
import styles from "./winnerScreen.module.scss";
import Confetti from "react-dom-confetti";
import { useSpring, animated } from "react-spring";

const WordChoice = ({ imageURL, Message, color, throwConfetti }) => {
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 9000,
    stagger: 20,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const [test, setTest] = React.useState(false);
  const spring = useSpring({
    from: {
      // transform: `translate(-50px)`,
      marginBottom: "-300px",
    },
    to: {
      // transform: `translate(0px)`,
      marginBottom: "0px",
    },
    config: {
      mass: 6,
    },
  });

  React.useEffect(() => {
    setTest(throwConfetti);
    return () => { };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.messageBox}>
      <Confetti active={test} config={config} />
      <animated.img
        className={styles.messageBoxAvatar}
        src={imageURL}
        alt="player"
      />
      <animated.section className={styles.messageBoxMessage}>
        <p style={(spring, { color: color })}>{Message}</p>
      </animated.section>
    </section>
  );
};

export default WordChoice;
