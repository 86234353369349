import React from "react";

import { motion } from "framer-motion";
import "./ohNoCards.scss";

import rotatebgCard from "../../../img/game-mode/ohno/Cards/rotateb.png";
import stopbgCard from "../../../img/game-mode/ohno/Cards/stopb.png";
import plus2bgCard from "../../../img/game-mode/ohno/Cards/plus2b.png";
import wildFront from "../../../img/game-mode/ohno/Cards/wild.png";

const OhNoCards = ({ socket, userDetails, hand, setHand, setTable }) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const listItem = {
    hidden: { opacity: 0, y: -300, x: -300 },
    show: { opacity: 1, y: 0, x: 0 },
    transition: {
      type: "spring",
      stiffness: 0,
    },
  };

  // const handleRemove = (card) => {
  //   setTable((e) => [
  //     ...e,
  //     {
  //       type: card.type,
  //       cc: card.cc,
  //       ccb: card.ccb,
  //       colour: card.colour,
  //       value: card.value,
  //     },
  //   ]);

  //   const newList = hand.filter((item) => item.id !== card.id);
  //   setHand(newList);
  // };

  const playCard = (card) => {
    socket.emit("gameData", {
      type: "playCard",
      card: card,
    }, (res) => {

    });
  };

  return (
    <motion.section
      variants={container}
      initial="hidden"
      animate="show"
      className="ohNoCards-middle"
    >
      {hand.map((card, i) =>
        card.value === "ROTATE" ? (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{
              backgroundImage: "url(" + rotatebgCard + ")",
              backgroundSize: "cover",
              backgroundColor: card.cc,
            }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            {/* <img
              src={rotateCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__top-img"
              alt=""
            />
            <img
              src={rotateCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__bot-img"
              alt=""
            /> */}
          </motion.li>
        ) : card.value === "+2" ? (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{
              backgroundImage: "url(" + plus2bgCard + ")",
              backgroundSize: "cover",
              backgroundColor: card.cc,
            }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            {/* <img
              src={skipCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__top-img"
              alt=""
            />
            <img
              src={skipCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__bot-img"
              alt=""
            /> */}
          </motion.li>
        ) : card.value === "SKIP" ? (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{
              backgroundImage: "url(" + stopbgCard + ")",
              backgroundSize: "cover",
              backgroundColor: card.cc,
            }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            {/* <img
              src={skipCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__top-img"
              alt=""
            />
            <img
              src={skipCard}
              style={{ backgroundColor: card.ccb }}
              className="ohNoCards-middle__deck__bot-img"
              alt=""
            /> */}
          </motion.li>
        ) : card.type === "WildDrawFour" ? (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{
              backgroundImage: "url(" + wildFront + ")",
              backgroundSize: "cover",
              backgroundColor: card.cc,
            }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            <p className="ohNoCards-middle__deck__top">{card.value}</p>
            <p className="ohNoCards-middle__deck__bot">{card.value}</p>
          </motion.li>
        ) : card.type === "WildColourChange" ? (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{
              backgroundImage: "url(" + wildFront + ")",
              backgroundSize: "cover",
              backgroundColor: card.cc,
            }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            <p className="ohNoCards-middle__deck__top">{card.value}</p>
            <p className="ohNoCards-middle__deck__bot">{card.value}</p>
          </motion.li>
        ) : (
          <motion.li
            className="ohNoCards-middle__deck"
            key={card.id}
            onClick={(e) => {
              // handleRemove(card);
              playCard(card);
            }}
            style={{ background: card.cc }}
            variants={listItem}
            whileHover={{ y: 0, height: 220, zIndex: 10 }}
          >
            <p className="ohNoCards-middle__deck__top">{card.value}</p>
            <p className="ohNoCards-middle__deck__bot">{card.value}</p>
          </motion.li>
        )
      )}
    </motion.section>
  );
};

export default OhNoCards;
