import React from "react";
import { toast } from "react-toastify";

import { motion } from "framer-motion";
import styles from "./roomSettings.module.scss";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useHistory } from "react-router-dom";

interface Props {
  socket: any;
  userDetails: any;
  setUserDetails: any;
  selectedRoomType: any;
  setSelectedRoomType: any;
  setOpenSideMenu: any;
}
const OwnRoom: React.FC<Props> = ({
  socket,
  userDetails,
  setUserDetails,
  selectedRoomType,
  setSelectedRoomType,
  setOpenSideMenu,
}) => {
  let history = useHistory();

  //M UI
  const [passwordChecked, setPasswordChecked] = React.useState(false);

  const togglePasswordChecked = () => {
    setPasswordChecked((prev) => !prev);
    setRoomPassword("");
  };

  const [roomTitle, setTitleName] = React.useState("");
  const [roomPassword, setRoomPassword] = React.useState("");
  const [roomAlreadyActive, setRoomAlreadyActive] = React.useState(false);

  React.useEffect(() => {
    socket.emit(
      "getOwnRoom",
      (response: {
        status: boolean;
        data: {
          roomStatus: boolean;
          roomOwner: boolean;
          mode: any;
          configSettings: { title: string };
        };
        error: string;
      }) => {
        const data = response.data;

        if (data.roomOwner) {
          setRoomAlreadyActive(true);
          // setSelectedRoomType(data.mode);

          setTitleName(data.configSettings.title);
        } else {
          setRoomAlreadyActive(false);
        }
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoomValueChange = (value: any) => {
    setTitleName(value.target.value);
  };

  const handlePasswordValueChange = (value: any) => {
    setRoomPassword(value.target.value);
  };

  const handleChange = (event: any) => {
    event.preventDefault(true);

    if (roomTitle === "") {
      toast.error("One or more fields are not filled in!", {
        position: "top-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      socket.emit(
        "createOwnRoom",
        {
          title: roomTitle,
          password: roomPassword,
          mode: selectedRoomType,
        },
        (response: {
          status: boolean;
          data: { name: string; room: string; active: boolean; guest: boolean };
          error: string;
        }) => {
          if (response) {
            if (response.status === false) {
              toast.error(response.error, {
                position: "top-left",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              return;
            }

            const data = response.data;
            setUserDetails({
              socketID: userDetails.socketID,
              name: data.name,
              room: data.room,
              status: data.active,
              guest: data.guest,
            });

            setSelectedRoomType(0);
            setOpenSideMenu(false);

            history.push(`/room/${data.room}`);
          }
        }
      );
    }
  };

  const closeRoom = () => {
    socket.emit("closeRoom", (response: boolean) => {
      if (response) {
        setSelectedRoomType(0);
        setOpenSideMenu(false);
      }
    });
  };

  const updateRoom = () => {
    socket.emit(
      "updateRoom",
      {
        title: roomTitle,
        password: roomPassword,
      },
      (response: any) => {
        if (response.status === true) {
          setSelectedRoomType(0);
          setOpenSideMenu(false);
        } else {
        }
      }
    );
  };

  return (
    <section className={styles.settings}>
      <motion.section
        className={styles.ownroomDashboard}
        initial={{ y: -1000 }}
        animate={{ y: 0 }}
        transition={{
          when: "BeforeChildren",
        }}
      >
        <form className={styles.roomSettings} onSubmit={(e) => handleChange(e)}>
          <section className={styles.navButtons}>
            <button
              type="button"
              onClick={() => {
                setSelectedRoomType(0);
              }}
              className={styles.settingsClose}
            >
              <HighlightOffIcon style={{ fontSize: "1.2rem" }} />
            </button>
            <p className={styles.roomSettingsHeading}>Manage Room</p>
            <button
              type="button"
              onClick={() => togglePasswordChecked()}
              className={styles.settingsLock}
            >
              {passwordChecked ? (
                <LockTwoToneIcon style={{ fontSize: "1.2rem" }} />
              ) : (
                <LockOpenTwoToneIcon style={{ fontSize: "1.2rem" }} />
              )}
            </button>
          </section>
          {selectedRoomType === 1 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>Room Lobby</p>
              <p className={styles.roomInfoDescription}>
                Empty room - You can switch to a different game in the settings.
              </p>
            </section>
          ) : selectedRoomType === 2 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>Draw Together</p>
              <p className={styles.roomInfoDescription}>
                In this game you will be able to guess drawings drawn by other
                members connected to the room to see who can get the most
                correct. A drawer is choosen at random or you can be added to
                the queue manually.
              </p>
              <p className={styles.roomInfoSpecials}>Score Tracked</p>
            </section>
          ) : selectedRoomType === 3 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>Trivia</p>
              <p className={styles.roomInfoDescription}>
                Choose from a list of categories and answers some trivia
                questions with your friends. Some questions will have 4 options
                to choose from and some may require you to write the answer
                (these tend to the scrambled questions).
              </p>
              <p className={styles.roomInfoSpecials}>Score Tracked</p>
            </section>
          ) : selectedRoomType === 4 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>OhNo</p>
              <p className={styles.roomInfoDescription}>
                Each player will be given 7 cards and you will take it in turns
                to place down a matching colour or a number. The person who
                places down all their cards first will win.
              </p>
              <p
                style={{ borderLeft: "4px solid orange" }}
                className={styles.roomInfoDescription}
              >
                PLUS 4 cards are chained until someone without a plus 4 is next,
                that person will have to pick up the total amount of cards
                chained.
              </p>
              <p
                style={{ borderLeft: "4px solid orange" }}
                className={styles.roomInfoDescription}
              >
                PLUS 2 is not chained and the next person whos turn it is will
                just pick up 2 cards/
              </p>
              <p className={styles.roomInfoDescription}>
                Rotate and block cards will do as expected...
              </p>
              {/* <p className={styles.roomInfoSpecials}>Score Tracked</p> */}
            </section>
          ) : selectedRoomType === 5 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>MatchSaga</p>
              <p className={styles.roomInfoDescription}>
                Match more than 2 shapes or colours together to get a match. If
                you match them fast enough you will recieve buffs. Compete with
                your friends and see who can get the highest score.
              </p>
              <p className={styles.roomInfoSpecials}>Score Tracked</p>
            </section>
          ) : selectedRoomType === 6 ? (
            <section className={styles.roomInfo}>
              <p className={styles.roomInfoHeaders}>Watch Together</p>
              <p className={styles.roomInfoDescription}>
                Play any video from YouTube, Facebook, Twitch, SoundCloud,
                Streamable, Vimeo, Wistia, Mixcloud, DailyMotion and Kaltura
                using the URL. Watch with your friends in realtime.
              </p>
              <p className={styles.roomInfoDescription}>
                Some links may not work!
              </p>
            </section>
          ) : (
            <section></section>
          )}
          <input
            className={styles.roomSettingsName}
            placeholder={"Room Title"}
            value={roomTitle}
            onChange={handleRoomValueChange}
          />

          {passwordChecked ? (
            <input
              className={styles.roomSettingsPassword}
              type="password"
              placeholder="Password"
              value={roomPassword}
              onChange={handlePasswordValueChange}
            />
          ) : (
            <></>
          )}

          {roomAlreadyActive ? (
            <section>
              <button
                type="button"
                onClick={() => closeRoom()}
                className={styles.settingsSubmit}
              >
                <span>close</span>
              </button>
              <button
                type="button"
                onClick={() => updateRoom()}
                className={styles.settingsSubmit}
              >
                <span>update</span>
              </button>
            </section>
          ) : (
            <button type="submit" className={styles.settingsSubmit}>
              <span>Create</span>
            </button>
          )}
        </form>
      </motion.section>
    </section>
  );
};

export default OwnRoom;
