import React, { useState, useRef, useEffect } from 'react'
import styles from "./audioPlayer.module.scss";

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';

const AudioPlayer = ({ audioURL }) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  // references
  const audioPlayer = useRef();   // reference our audio component
  const progressBar = useRef();   // reference our progress bar
  const animationRef = useRef();  // reference the animation

  const getDuration = function (url, next) {
    var _player = new Audio(url);
    _player.addEventListener("durationchange", function (e) {
      if (this.duration != Infinity) {
        var duration = this.duration
        _player.remove();
        next(duration);
      };
    }, false);
    _player.load();
    _player.currentTime = 24 * 60 * 60; //fake big time
    _player.volume = 0;
    _player.play();
    //waiting...
  };


  useEffect(() => {
    const seconds = Math.floor(audioPlayer.current.duration);
    console.log(seconds)
    getDuration(audioURL, (d) => setDuration(d))
    progressBar.current.max = seconds;
  }, [audioPlayer?.current?.loadedmetadata, audioPlayer?.current?.readyState]);

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes}:${returnedSeconds}`;
  }

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.load();
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying)
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  }

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  }

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--seek-before-width', `${progressBar.current.value / duration * 100}%`)
    setCurrentTime(progressBar.current.value);
  }

  const backThirty = () => {
    progressBar.current.value = Number(progressBar.current.value - 30);
    changeRange();
  }

  const forwardThirty = () => {
    progressBar.current.value = Number(progressBar.current.value + 30);
    changeRange();
  }

  return (
    <div className={styles.audioPlayer}>
      <div style={{ width: currentTime / duration * 100 + "%" }} className={styles.audioPlayer2}></div>
      <audio ref={audioPlayer} src={audioURL} preload="metadata"></audio>
      <button onClick={togglePlayPause} className={styles.playPause}>
        {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
      </button>
      {/* current time */}
      <div style={{ display: "none" }} className={styles.currentTime}>{calculateTime(currentTime)}</div>

      {/* progress bar */}
      <div style={{ display: "none" }}>
        <input type="range" className={styles.progressBar} defaultValue="0" ref={progressBar} onChange={changeRange} />
      </div>

      {/* duration */}
      <div style={{ display: "none" }} className={styles.duration}>{(duration && !isNaN(duration)) && calculateTime(duration)}</div>
    </div>
  )
}

export { AudioPlayer }



// <div className={styles.audioPlayer}>
//       {/* <div style={{ width: currentTime / duration * 100 + "%", background: "#74b9ff" }} className={styles.audioPlayer2}></div> */}
//       <div style={{ width: currentTime / duration * 100 + "%", background: "#74b9ff" }} className={styles.audioPlayer2}></div>
//       <audio ref={audioPlayer} src={audioURL} preload="metadata"></audio>
//       <button onClick={togglePlayPause} className={styles.playPause}>
//         {isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
//       </button>
//     </div>