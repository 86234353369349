import React from "react";
import styles from "./leaderboardScreen.module.scss";
import Confetti from "react-dom-confetti";
import { useSpring, animated } from "react-spring";

const LeaderboardScreen = ({ leaderboard, color, throwConfetti }) => {
  const spring = useSpring({
    from: {
      // transform: `translate(-50px)`,
      marginBottom: "-300px",
    },
    to: {
      // transform: `translate(0px)`,
      marginBottom: "0px",
    },
    config: {
      mass: 6,
    },
  });

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 9000,
    stagger: 20,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  };

  const [test, setTest] = React.useState(false);

  React.useEffect(() => {
    setTest(throwConfetti);
    return () => { };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={styles.leaderboardBox}>
      <Confetti active={test} config={config} />
      <p className={styles.winnerHeader}>{leaderboard[0].name} has won!</p>

      <animated.section style={spring} className={styles.leaderboardBoxBoard}>
        {leaderboard.length > 0 ? (
          <section className={styles.scoreCard}>
            <animated.img
              src={leaderboard[0].avatar}
            />
            <section className={styles.details}>
              <p style={{ background: "#ffeaa7" }} className={styles.detailsName}>
                {leaderboard[0].name}
              </p>
              <p style={{ background: "#ffeaa7" }} className={styles.detailsScore}>
                {leaderboard[0].score}
              </p>
              <p style={{ background: "#ffeaa7" }} className={styles.test}>
                {1}
              </p>
            </section>
          </section>
        ) : (
          <></>
        )}
        {leaderboard.length > 1 ? (
          <section className={styles.scoreCard}>
            <animated.img
              src={leaderboard[1].avatar}
            />
            <section className={styles.details}>
              <p style={{ background: "#dfe6e9" }} className={styles.detailsName}>
                {leaderboard[1].name}
              </p>
              <p style={{ background: "#dfe6e9" }} className={styles.detailsScore}>
                {leaderboard[1].score}
              </p>
              <p style={{ background: "#dfe6e9" }} className={styles.test}>
                {2}
              </p>
            </section>
          </section>
        ) : (
          <></>
        )}
        {leaderboard.length > 2 ? (
          <section className={styles.scoreCard}>
            <animated.img

              src={leaderboard[2].avatar}
            />
            <section className={styles.details}>
              <p
                style={{ background: "#fab1a0" }}
                className={styles.detailsName}
              >
                {leaderboard[2].name}
              </p>
              <p
                style={{ background: "#fab1a0" }}
                className={styles.detailsScore}
              >
                {leaderboard[2].score}
              </p>
              <p style={{ background: "#fab1a0" }} className={styles.test}>
                {3}
              </p>
            </section>
          </section>
        ) : (
          <></>
        )}
      </animated.section>
    </section>
  );
};

export default LeaderboardScreen;
