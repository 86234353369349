import React from "react";

import ScrollContainer from "react-indiana-drag-scroll";

import styles from "./selectionScreen.module.scss";

// Image Loading - Prevents DOM Issues from loading late
const Images = [
  require("../../../img/game-mode/trivia/cover-world-capitals.png"),
  require("../../../img/game-mode/trivia/cover-guess-the-lyrics.png"),
  require("../../../img/game-mode/trivia/cover-phrases.png"),
  require("../../../img/game-mode/trivia/cover-word-scramble-countries.png"),
  require("../../../img/game-mode/trivia/cover-word-scramble-food.png"),
  require("../../../img/game-mode/trivia/cover-monuments.png"),
  require("../../../img/game-mode/trivia/cover-emoji-puzzle.png"),
  require("../../../img/game-mode/trivia/cover-riddles.png"),
  require("../../../img/game-mode/trivia/main-trivia-background.png"),
];


const SelectionScreen = ({ readyMessage, socket }) => {
  const [scrolling, setScrolling] = React.useState(false);

  const catagoryList = [
    {
      name: "All",
      code: -1,
      emoji: "😄",
      background: "#1abc9c",
      textColour: "#ffffff",
    },
    {
      name: "General Knowledge",
      code: 9,
      emoji: "🤓",
      background: "#2ecc71",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Books",
      code: 10,
      emoji: "📚",
      background: "#3498db",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Film",
      code: 11,
      emoji: "🎬",
      background: "#9b59b6",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Music",
      code: 12,
      emoji: "🎵",
      background: "#fd79a8",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Musicals & Theatres",
      code: 13,
      emoji: "🎭",
      background: "#f1c40f",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Television",
      code: 14,
      emoji: "📺",
      background: "#e67e22",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Video Games",
      code: 15,
      emoji: "🎮",
      background: "#e74c3c",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Board Games",
      code: 16,
      emoji: "🎲",
      background: "#e84393",
      textColour: "rgb(245, 245, 245)",
    },
    {
      name: "Science & Nature",
      code: 17,
      emoji: "🧪",
      background: "#16a085",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Science: Computers",
      code: 18,
      emoji: "🖥️",
      background: "#27ae60",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Science: Mathematics",
      code: 19,
      emoji: "➕",
      background: "#74b9ff",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Mythology",
      code: 20,
      emoji: "💎",
      background: "#2980b9",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Sports",
      code: 21,
      emoji: "⚽",
      background: "#8e44ad",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Geography",
      code: 22,
      emoji: "🗺️",
      background: "#ff7675",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "History",
      code: 23,
      emoji: "📜",
      background: "#f39c12",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Politics",
      code: 24,
      emoji: "🗳️",
      background: "#e17055",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Art",
      code: 25,
      emoji: "🎨",
      background: "#fab1a0",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Celebrities",
      code: 26,
      emoji: "🙌",
      background: "#fdcb6e",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Animals",
      code: 27,
      emoji: "🐿️",
      background: "#a29bfe",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Vehicles",
      code: 28,
      emoji: "🚙",
      background: "#1abc9c",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Comics",
      code: 29,
      emoji: "📔",
      background: "#00cec9",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Gadgets",
      code: 30,
      emoji: "🎧",
      background: "#2ecc71",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Japanese Anime and Manga",
      code: 31,
      emoji: "📔",
      background: "#3498db",
      textColour: "rgb(255, 255, 255)",
    },
    {
      name: "Cartoon and Animation",
      code: 32,
      emoji: "🐛",
      background: "#9b59b6",
      textColour: "rgb(255, 255, 255)",
    },
  ];

  const customCatagoryList = [
    {
      name: "World Capitals",
      code: 0,
      emoji: "🌍",
      background: "#fab1a0",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[0],
    },
    {
      name: "Riddles",
      code: 1,
      emoji: "🧶",
      background: "#fdcb6e",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[7],
    },
    {
      name: "Finish The Saying",
      code: 2,
      emoji: "💭",
      background: "#a29bfe",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[2],
    },
    {
      name: "Famous Landmarks",
      code: 3,
      emoji: "🗼",
      background: "#1abc9c",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[5],
    },
    {
      name: "Emoji Puzzle",
      code: 4,
      emoji: "🔥",
      background: "#ff7675",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[6],
    },
    {
      name: "Scramble - Countries",
      code: 5,
      emoji: "🗺️",
      background: "#3498db",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[3],
    },
    {
      name: "Scramble - Food",
      code: 6,
      emoji: "🍉",
      background: "#f1c40f",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[4],
    },
    {
      name: "Finish the lyrics",
      code: 7,
      emoji: "🎤",
      background: "#8e44ad",
      textColour: "rgb(255, 255, 255)",
      backgroundImage: Images[1],
    },
  ];
  const communityCatagoryList = [
    {
      name: "Create",
      code: -1,
      emoji: "+",
      background: "rgba(173, 173, 173, 0.288)",
      textColour: "rgba(255, 255, 255, 0.418)",
    },
  ];

  return (
    <section className={styles.catagory}>
      {readyMessage !== "" ? (
        <section className={styles.screenMessageOverlay}>
          <p>{readyMessage}</p>
        </section>
      ) : (
        <></>
      )}

      <br />
      <br />
      <section className={styles.catagoryHeaders}>
        <p className={styles.catagoryHeadersMainTitle}>Catagory</p>
        <p className={styles.catagoryHeadersNote}>
          Questions from https://opentdb.com/ - Feel free to submit more
          questions there!
              </p>
      </section>
      <ScrollContainer
        className={styles.catagoryList}
        horizontal={true}
        onStartScroll={() => {
          setScrolling(true);
        }}
        onEndScroll={() => {
          setScrolling(false);
        }}
      >
        {catagoryList.map((catagory) => (
          <section
            className={styles.catagoryListBackground}
            style={{
              background: catagory.background,
            }}
            onClick={() => {
              if (!scrolling) {
                socket.emit("gameData", {
                  type: "chooseCategory",
                  questionType: 0,
                  code: catagory.code,
                }, (res) => {

                });
              }
            }}
          >
            <p className={styles.catagoryListBackgroundEmoji}>
              {catagory.emoji}
            </p>
            <p style={{ color: catagory.textColour }}>{catagory.name}</p>
          </section>
        ))}
      </ScrollContainer>
      <section className={styles.catagoryHeaders}>
        <p className={styles.catagoryHeadersMainTitle}>Custom Packs</p>
        <p className={styles.catagoryHeadersNote}>
          Set List of questions created and checked by us!
              </p>
      </section>
      <ScrollContainer
        className={styles.catagoryList}
        horizontal={true}
        onStartScroll={() => {
          setScrolling(true);
        }}
        onEndScroll={() => {
          setScrolling(false);
        }}
      >
        {customCatagoryList.map((catagory) => (
          <section
            style={{
              // opacity: scrolling ? 0.7 : 1,
              background: catagory.background,
              backgroundImage: `url(${catagory.backgroundImage})`,
              backgroundSize: "cover",
            }}
            className={styles.catagoryListBackground}
            onClick={() => {
              socket.emit("gameData", {
                type: "chooseCustomCategory",
                questionType: 0,
                code: catagory.code,
              }, (res) => {

              });
            }}
          >
            <p className={styles.catagoryListBackgroundEmoji}>
              {catagory.emoji}
            </p>
            <p style={{ color: catagory.textColour }}>{catagory.name}</p>
          </section>
        ))}
      </ScrollContainer>
      <section className={styles.catagoryHeaders}>
        <p className={styles.catagoryHeadersMainTitle}>Community Packs</p>
        <p className={styles.catagoryHeadersNote}>
          Approved Community Suggestions.
              </p>
      </section>
      <ScrollContainer
        className={styles.catagoryList}
        horizontal={true}
        onStartScroll={() => {
          setScrolling(true);
        }}
        onEndScroll={() => {
          setScrolling(false);
        }}
      >
        {communityCatagoryList.map((catagory) => (
          <section
            style={{
              // opacity: scrolling ? 0.7 : 1,
              background: catagory.background,
            }}
            className={styles.catagoryListBackground}
            onClick={() => {
              socket.emit("gameData", {
                type: "chooseCustomCategory",
                questionType: 0,
                code: catagory.code,
              }, (res) => {

              });
            }}
          >
            <p className={styles.catagoryListBackgroundEmoji}>
              {catagory.emoji}
            </p>
            <p style={{ color: catagory.textColour }}>{catagory.name}</p>
          </section>
        ))}
      </ScrollContainer>
    </section>
  );
};

export default SelectionScreen;
