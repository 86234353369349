import React from "react";
import io from "socket.io-client";
import Cookies from "js-cookie";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";

import {
  BrowserRouter as Router,
} from "react-router-dom";

import styles from "./dashboard.module.scss";

import SideBar from "../components/sideBar";
import RoomScreen from "../joinedRoom/MainScreen";
import CreateProfile from "../createProfile/CreateProfile";
import Chat from "../components/chat";
import RoomSettings from "../components/roomSettings"
import RoomPassword from "../components/roomPassword"
import PublicRooms from "../publicRooms";

const axios = require("axios").default;

const socketUrl = "/";
let socket = null;

const userDetailsTemplate = {
  socketID: "",
  name: "",
  room: "",
  status: false,
};

const Dashboard = ({ auth, setAuth }) => {
  const [openSideMenu, setOpenSideMenu] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState("");
  const [userDetails, setUserDetails] = React.useState(userDetailsTemplate);

  const [selectedRoomDetails, setSelectedRoomDetails] = React.useState({});
  const [selectedRoomType, setSelectedRoomType] = React.useState(0);
  const [muteList, setMuteList] = React.useState([]);

  let history = useHistory();

  const joinRoom = (roomName, password = "") => {
    socket.emit("join", { room: roomName, password: password }, (response) => {
      if (response.status) {
        history.push(`/room/${roomName}`);

        setUserDetails({
          socketID: userDetails.socketID,
          name: userDetails.name,
          room: roomName,
          status: userDetails.room,
        });

        setSelectedRoomType(0)
      } else {
        if (response.error === "Wrong Password") {
          setSelectedRoomDetails({ roomOwner: roomName })
          setSelectedRoomType(-1)
        }

        if (response.alert) {
          toast.dark(response.error, {
            position: "top-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

        history.push(`/`);
      }
    });
  };


  React.useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = null;
    }
  });

  React.useEffect(() => {
    if (!socket) {
      const token = Cookies.get("token");

      if (token) {
        socket = io(socketUrl, { query: "auth_token=" + token });
      }
      else {
        socket = io(socketUrl)
      }
    }
  }, []);

  React.useEffect(() => {
    socket.on("disconnect", function () {
      window.location.reload(false);
    });
  }, []);

  React.useEffect(() => {
    // Connection failed
    socket.on("error", function (err) {
      Cookies.remove("token");
      // setAuth(false);
    });

    // Connection succeeded
    socket.on("success", function (data) {
      setUserDetails({
        socketID: "",
        name: data.user,
        room: "Lobby",
        status: true,
      });

      const currentPath = window.location.pathname.split("/")

      if (currentPath[1] === "room") {
        joinRoom(currentPath[2], "")
      }
    });

    socket.on("multipleLogs", function (data) {
      if (data === true) {
        socket.disconnect();
        Cookies.remove("token");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // React.useEffect(() => {
  //   fetch("http://geoplugin.net/json.gp").then((res) => {
  //     res.json().then((userD) => {
  //       socket.emit("socketCountryCode", userD.geoplugin_countryCode);
  //     });
  //   });
  // }, []);

  const [readyCheck, setReadyCheck] = React.useState("");
  const [details, setDetails] = React.useState({});

  React.useEffect(() => {
    const token = Cookies.get("token");
    socket.emit("userNameCheck", function (res) {
      // CHECK IF THE ACCOUNT EXISTS!!!
      if (res.status === false) {
        setReadyCheck("Username");
        if (token) {
          // Authenticate the token?
          // Attempt to connect to the socket server
          // Maybe set token true and call the dashboard screen?
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
            credentials: "same-origin",
          };

          fetch("/getProfile", requestOptions)
            .then((res) => res.json())
            .then((data) => {
              if (data) {
                setDetails(data);
              }
            });
        }
      } else {
        setUserDetails(res.data);
        setReadyCheck("Ready");
      }
    });
  }, []);


  const [chatMessageList, setChatMessagesList] = React.useState([]);

  // const [notificationList, setNotificationList] = React.useState([
  //   {
  //     title: "message.title",
  //     subTitle: "message.subTitle",
  //     desc: "message.desc",
  //     avatar: "message.avatar",
  //   },
  //   {
  //     title: "message.title",
  //     subTitle: "message.subTitle",
  //     desc: "message.desc",
  //     avatar: "message.avatar",
  //   },
  // ]);


  React.useEffect(() => {
    socket.on("newMessage", function (message) {
      if (message.type === "Private" && selectedUser !== message.from) {
        toast.dark(<span>{`New Message from ${message.from}`}</span>, {
          position: "top-left",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }


      setChatMessagesList((messageList) => {
        if (messageList.length > 35) {
          return [
            ...messageList.slice(1, messageList.length + 1),
            {
              from: message.from,
              fromAvatar: message.fromAvatar,
              message: message.text,
              audio: message.audio,
              avatar: message.avatar,
              type: message.type,
              to: message.to,
              toAvatar: message.toAvatar,
            },
          ];
        } else {
          return [
            ...messageList,
            {
              from: message.from,
              fromAvatar: message.fromAvatar,
              message: message.text,
              audio: message.audio,
              avatar: message.avatar,
              type: message.type,
              to: message.to,
              toAvatar: message.toAvatar,
            },
          ];
        }
      });
    });

    socket.on("newMessageNotification", function (message) {
      //   setNotificationList((messageList) => {
      //     if (messageList.length > 35) {
      //       return [
      //         ...messageList.slice(1, messageList.length + 1),
      //         {
      //           title: message.title,
      //           subTitle: message.subTitle,
      //           desc: message.desc,
      //           avatar: message.avatar,
      //         },
      //       ];
      //     } else {
      //       return [
      //         ...messageList,
      //         {
      //           title: message.title,
      //           subTitle: message.subTitle,
      //           desc: message.desc,
      //           avatar: message.avatar,
      //         },
      //       ];
      //     }
      //   });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [0]);



  if (readyCheck === "Username") {
    return <CreateProfile details={details} />;
  } else if (readyCheck === "Ready") {
    return (
      <Router>
        <section className={styles.mainDash}>
          <section>
            {selectedRoomType === -1 ? (
              <RoomPassword socket={socket}
                joinRoom={joinRoom}
                selectedRoomDetails={selectedRoomDetails}
                setSelectedRoomType={setSelectedRoomType}
              />
            ) : selectedRoomType > 0 || selectedRoomType === -2 ? (
              <RoomSettings socket={socket}
                userDetails={userDetails}
                setUserDetails={setUserDetails}
                setSelectedRoomType={setSelectedRoomType}
                selectedRoomType={selectedRoomType}
                setOpenSideMenu={setOpenSideMenu} />
            ) : (
              <></>
            )}

            {userDetails.room === "Lobby" || userDetails.room === "" ? (
              <PublicRooms
                socket={socket}
                userDetails={userDetails}
                setSelectedRoomType={setSelectedRoomType}
                setUserDetails={setUserDetails}
                joinRoom={joinRoom}
                setSelectedRoomDetails={setSelectedRoomDetails}
              />
            ) : (
              <RoomScreen
                socket={socket}
                setUserDetails={setUserDetails}
                userDetails={userDetails}
              />
            )}

          </section>


          <SideBar
            Cookies={Cookies}
            socket={socket}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            setSelectedRoomType={setSelectedRoomType}
            setOpenSideMenu={setOpenSideMenu}
            openSideMenu={openSideMenu}
            chatMessageList={chatMessageList}
            setChatMessagesList={setChatMessagesList}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            muteList={muteList}
            setMuteList={setMuteList}
          />

          <Chat muteList={muteList} setMuteList={setMuteList} socket={socket} userDetails={userDetails} chatMessageList={chatMessageList}
            setChatMessagesList={setChatMessagesList} />
        </section>
      </Router>
    );
  } else {
    return (
      <section className={styles.loading}>
        <div className={styles.loaderAnimation}></div>
      </section>
    );
  }
};

export default Dashboard;
