import React from "react";
import "./matchSaga.scss"

import botImage from "../../../img/game-mode/ohno/bot-profile.png";
import BoardViewMultiplayer from "./components/multiplayerBoard"

const MatchSaga = ({ socket, userDetails }) => {

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };


  React.useEffect(() => {
    socket.emit("gameData", {
      type: "getInitalUserData",
    }, (res) => {

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const [currentScreen, setCurrentScreen] = React.useState(0);

  const [queuedPlayers, setQueuedPlayers] = React.useState([
    {
      avatar: "",
      name: "User1",
      wins: "",
    },
  ]);
  const [playingPlayers, setPlayingPlayers] = React.useState([
    {
      avatar: "",
      name: "User1",
      wins: "",
    },
  ]);

  const [winnerBoardList, setWinnerBoardList] = React.useState([])

  const addPlayerToPlaying = (playerName) => {
    socket.emit("gameData", {
      type: "addPlayerToPlaying",
      playerName: playerName,
    }, (res) => {

    });
  };

  const removePlayerFromPlaying = (playerName) => {
    socket.emit("gameData", {
      type: "removePlayerFromPlaying",
      playerName: playerName,
    }, (res) => {

    });
  };

  const startGame = () => {
    socket.emit("gameData", {
      type: "startGame",
    }, (res) => {

    });
  };


  React.useEffect(() => {
    socket.on("getCurrentUserList", function (cards) {
      setQueuedPlayers(cards);
    });

    // socket.on("winner", function (winner) {
    //   setWinner({ name: winner.name, avatar: winner.avatar });
    //   setShowWinnerScreen(true);
    // });

    // socket.on("screenMessage", function (message) {
    //   setScreenMessage(message);
    //   showNotification();
    // });

    socket.on("getSelectedPlayers", function (users) {
      setPlayingPlayers(users);
    });

    socket.on("getWinnerSelectedPlayers", function (users) {
      setWinnerBoardList(users);
    });

    socket.on("screenUpdate", function (screen) {
      setCurrentScreen(screen);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const sendNewSoloScore = (score) => {
    socket.emit("gameData", {
      type: "addSoloPlayerScore",
      soloScore: score
    }, (res) => {

    });
  }

  return (
    <section className="swap-board-main">
      {winnerBoardList.length > 0 ? (
        <section className={"ohNoWinner"}>
          <button
            onClick={() => setWinnerBoardList([])}
            className={"ohNoWinnerClose"}
          >
            Close
          </button>
          <p className={"ohNoWinnerWinner"}>WINNER</p>
          <img
            className={"ohNoWinnerPImage"}

            onError={(e) => addDefaultSrc(e)}
            src={winnerBoardList[0].avatar}
            alt="winner"
          />
          <p className={"ohNoWinnerUsername"}>{winnerBoardList[0].name}</p>
        </section>
      ) : (
        <></>
      )}

      {currentScreen === 0 ? (
        <section className={"ludoMainMenu"}>

          <button
            className={"startGame"}
            onClick={() => startGame()}
          >
            Start Game
          </button>


          <ul style={{ overflow: "scroll" }} className={"ohNoMainMenuPlayerList"}>
            {playingPlayers.map((player) => (
              <li className={"ohNoMainMenuPlayerListHolder"}>
                <img
                  onError={(e) => addDefaultSrc(e)}

                  src={player.name[0] === "@" ? botImage : player.avatar}
                  alt={player.name}
                  onClick={() => removePlayerFromPlaying(player.name)}
                />
                <p style={{ color: "white" }}>
                  {player.name}
                </p>
                <p style={{ color: "white" }}>
                  {player.wins}
                </p>
              </li>
            ))}
          </ul>
          <br />
          <p
            style={{
              margin: "10px",
              fontWeight: "bold",
              fontSize: "1rem",
              color: "white",
              opacity: 0.5
            }}
          >
            Users Currently Not Playing
          </p>

          <ul className={"ohNoMainMenuPlayerList"}>
            {queuedPlayers.map((player) => (
              <li className={"ohNoMainMenuPlayerListHolder"}>
                <img
                  onError={(e) => addDefaultSrc(e)}
                  src={player.avatar}
                  alt={player.name}
                  onClick={() => addPlayerToPlaying(player.name)}
                />
                <p style={{ color: "white" }}>
                  {player.name}
                </p>
                <p style={{ color: "white" }}>
                  {player.wins}
                </p>
              </li>
            ))}
          </ul>
        </section>

      ) : (
        <BoardViewMultiplayer socket={socket} userDetails={userDetails} />
      )}
    </section>
  );
};

export default MatchSaga;
