import React from "react";
import "./standardMessageScreen.scss";
import { useSpring, animated } from "react-spring";

const WordChoice = ({ imageURL, Message, color }) => {
  const spring = useSpring({
    from: {
      // transform: `translate(-50px)`,
      marginBottom: "-300px",
    },
    to: {
      // transform: `translate(0px)`,
      marginBottom: "0px",
    },
    config: {
      mass: 6,
    },
  });

  return (
    <section className="std-message-box">
      <animated.img
        className="std-message-box__avatar"
        src={imageURL}
        alt="player"
      />
      <animated.section className="std-message-box__main">
        <p style={(spring, { color: { color } })}>{Message}</p>
      </animated.section>
    </section>
  );
};

export default WordChoice;
