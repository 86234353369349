import React from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import { toast } from 'react-toastify';

import "./modeSelectionScreen.scss";

const ModeSelectionScreen = ({ socket, setShowRoomStart }) => {
  const modes = [
    { modeName: "All", code: 0 },
    { modeName: "Easy", code: 1 },
    { modeName: "Medium", code: 2 },
    { modeName: "Hard", code: 3 },
  ]

  const rounds = [6, 8, 10, 12]

  const [selectedRound, setSelectedRound] = React.useState(8);

  return (
    <section className="draw-mode-box">
      <p
        onClick={() => setShowRoomStart(false)}
        className="draw-mode-select-close"
      >
        <CancelIcon />
      </p>
      <section>
        <section className="draw-mode-holder">
          <p>Rounds</p>
          <section className="draw-mode-box__join">
            {rounds.map((round) => (
              <button
                style={{
                  minWidth: "70px",
                  background:
                    selectedRound === round ? "rgb(70, 224, 78)" : "white",
                }}
                className="draw-mode-select-button"
                key={round}
                onClick={() => {
                  setSelectedRound(round);
                }}
              >
                {round}
              </button>
            ))}
          </section>
        </section>
        <section className="draw-mode-holder">
          <p>Start by selecting a Topic:</p>
          <section className="draw-mode-box__join">
            {modes.map((modes) => (
              <button
                className="draw-mode-select-button"
                key={modes.modeName}
                onClick={() => {
                  socket.emit("gameData", {
                    type: "chooseAMode",
                    value: modes.modeName,
                    selectedRounds: selectedRound,
                    code: modes.code,
                  }, callback => {
                    // console.log(callback.error)

                    toast.error(callback.error, {
                      position: "top-left",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  });
                }}
              >
                {modes.modeName}
              </button>
            ))}
          </section>
        </section>
      </section>
    </section >
  );
};

export default ModeSelectionScreen;
