import React from "react";
import "./die.scss";

const Pip = () => <span className="pip" />;

const Face = ({
  children,
  index,
  setSelectedDice,
  resolved,
  selected,
  colourCode,
}) => (
  <div
    style={{
      // border: selected ? "2px solid red" : "none",
      opacity: resolved ? 0.1 : 1,
      background: selected ? colourCode : "white",
    }}
    onClick={(e) => setSelectedDice(index)}
    className="face"
  >
    {children}
  </div>
);

const Die = ({ value, index, setSelectedDice, resolved, selected, colourCode }) => {
  let pips = Number.isInteger(value)
    ? Array(value)
        .fill(0)
        .map((_, i) => <Pip key={i} />)
    : null;
  return (
    <Face
      index={index}
      setSelectedDice={setSelectedDice}
      resolved={resolved}
      selected={selected}
      colourCode={colourCode}
    >
      {pips}
    </Face>
  );
};

export default Die;
