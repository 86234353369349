import React from "react";
import useSound from "use-sound";
import Cell from "./Cell";
import Die from "./die";
import GameOverlay from "./gameOverlay";
import botImage from "../../../../img/game-mode/ohno/bot-profile.png";

//Sounds
import correctSound from "../../../../audio/SwapGame/correctSound.wav";
import correctFastSound from "../../../../audio/SwapGame/correctSound2.wav";
import errorSound from "../../../../audio/SwapGame/error.wav";

const BoardView = ({ userDetails, socket }) => {
  //Audio
  const [correctFastSoundPlay] = useSound(correctSound, { volume: 0.2 });
  const [correctSoundPlay] = useSound(correctFastSound, { volume: 0.2 });
  const [errorSoundPlay] = useSound(errorSound, { volume: 0.2 });

  //States
  const [selectedCells, setSelectedCells] = React.useState(0);
  const [buffProgress, setBuffProgress] = React.useState(0);

  const [validMove, setValidMove] = React.useState(true);

  const [selected, setSelected] = React.useState({});
  const [firstSelected, setFirstSelected] = React.useState({});

  const [selecteDice, setSelectedDice] = React.useState(0);
  const [boardCells, setBoardCells] = React.useState([]);

  const [currentlyPlaying, setCurrentlyPlaying] = React.useState("");
  const [currentPlayers, setCurrentPlayers] = React.useState([
    {
      name: "user1",
      colour: "green",
      avatar:
        "https://www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png",
      dice: [6, 6],
      currentTurn: false,
      waitingRoll: false,
    },
    {
      name: "user1",
      colour: "yellow",
      avatar:
        "https://www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png",
      dice: [6, 6],
      currentTurn: false,
      waitingRoll: false,
    },
    {
      name: "user1",
      colour: "blue",
      avatar:
        "https://www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png",
      dice: [6, 6],
      currentTurn: false,
      waitingRoll: false,
    },
    {
      name: "user1",
      colour: "red",
      avatar:
        "https://www.winhelponline.com/blog/wp-content/uploads/2017/12/user.png",
      dice: [6, 6],
      currentTurn: false,
      waitingRoll: false,
    },
  ]);

  React.useEffect(() => {
    socket.emit(
      "gameData",
      {
        type: "getCurrentBoard",
      },
      (res) => {}
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.on("getCurrentBoardOnly", function (data) {
      setBoardCells(data.board);
    });

    socket.on("userBoardDetails", function (data) {
      if (userDetails.name === data.currentUser) {
        if (
          data.playingUserList.filter(
            (player) => player.name === data.currentUser
          )[0].diceResolved[0] === true
        ) {
          setSelectedDice(1);
        } else {
          setSelectedDice(0);
        }
      }

      setCurrentlyPlaying(data.currentUser);
      setCurrentPlayers(data.playingUserList);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectPieceTile = (index) => {
    socket.emit(
      "gameData",
      {
        type: "selectBoardElements",
        selectedIndex: index,
        diceIndex: selecteDice,
      },
      (res) => {}
    );
  };

  const rollDice = () => {
    socket.emit(
      "gameData",
      {
        type: "rollDice",
      },
      (res) => {}
    );
  };

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/modes/bot-profile.png";
  };

  const endGame = () => {
    socket.emit(
      "gameData",
      {
        type: "endGame",
      },
      (res) => {}
    );
  };

  return (
    <div style={{}} className="ludoDashboard">
      {/* <button onClick={() => rollDice(1, 6)}>Roll</button> */}

      <section className="userDetails">
        <section className="userDetailsHolder">
          <section className="userDetailsHolderInfo">
            <p>{currentPlayers[0].name}</p>
            <img
              onError={(e) => addDefaultSrc(e)}
              src={
                currentPlayers[0].name[0] === "@"
                  ? "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/modes/bot-profile.png"
                  : currentPlayers[0].avatar
              }
              alt="player"
            />
          </section>
          {currentPlayers[0].name === currentlyPlaying ? (
            currentPlayers[0].waitingRoll ? (
              currentlyPlaying === userDetails.name ? (
                <button className="rollBtn" onClick={rollDice}>
                  Roll Dice
                </button>
              ) : (
                <button className="rollBtn" onClick={rollDice}>
                  Waiting...
                </button>
              )
            ) : currentlyPlaying === currentPlayers[0].name ? (
              <section className="userDetailsHolderDice">
                {currentPlayers[0].dice.map((v, index) => (
                  <Die
                    key={currentPlayers[0].name + v + index}
                    value={v}
                    index={index}
                    selected={index === selecteDice ? true : false}
                    setSelectedDice={setSelectedDice}
                    resolved={currentPlayers[0].diceResolved[index]}
                    colourCode={currentPlayers[0].colourCode}
                  />
                ))}
              </section>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </section>
        <button
          style={{ background: "#ff7675", color: "black", height: "50px" }}
          className={"startGame"}
          onClick={() => endGame()}
        >
          End Game
        </button>
        <section style={{ alignSelf: "end" }} className="userDetailsHolder">
          <section className="userDetailsHolderInfo">
            <p>{currentPlayers[3].name}</p>
            <img
              onError={(e) => addDefaultSrc(e)}
              src={
                currentPlayers[3].name[0] === "@"
                  ? "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/modes/bot-profile.png"
                  : currentPlayers[3].avatar
              }
              alt="player"
            />
          </section>
          {currentPlayers[3].name === currentlyPlaying ? (
            currentPlayers[3].waitingRoll ? (
              currentlyPlaying === userDetails.name ? (
                <button className="rollBtn" onClick={rollDice}>
                  Roll Dice
                </button>
              ) : (
                <button className="rollBtn" onClick={rollDice}>
                  Waiting...
                </button>
              )
            ) : currentlyPlaying === currentPlayers[3].name ? (
              <section className="userDetailsHolderDice">
                {currentPlayers[3].dice.map((v, index) => (
                  <Die
                    key={currentPlayers[3].name + v + index}
                    value={v}
                    index={index}
                    selected={index === selecteDice ? true : false}
                    setSelectedDice={setSelectedDice}
                    resolved={currentPlayers[3].diceResolved[index]}
                    colourCode={currentPlayers[0].colourCode}
                  />
                ))}
              </section>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </section>
      </section>
      <section style={{}} className="ludo-board">
        {boardCells !== [] ? (
          boardCells.map((row, rowIndex) => {
            return (
              <div>
                {row.map((col, colIndex) => {
                  return (
                    <Cell
                      key={rowIndex * boardCells.length + colIndex}
                      index={{
                        col: colIndex,
                        row: rowIndex,
                        id: rowIndex * boardCells.length + colIndex,
                      }}
                      col={col}
                      selectedCells={selectedCells}
                      setSelected={setSelected}
                      selected={selected}
                      setSelectedCells={setSelectedCells}
                      setFirstSelecte={setFirstSelected}
                      firstSelected={firstSelected}
                      onSelectPieceTile={onSelectPieceTile}
                    />
                  );
                })}
              </div>
            );
          })
        ) : (
          <></>
        )}
      </section>
      <section className="userDetails">
        <section className="userDetailsHolder">
          <section className="userDetailsHolderInfo">
            <p>{currentPlayers[1].name}</p>
            <img
              onError={(e) => addDefaultSrc(e)}
              src={
                currentPlayers[1].name[0] === "@"
                  ? "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/modes/bot-profile.png"
                  : currentPlayers[1].avatar
              }
              alt="player"
            />
          </section>
          {currentPlayers[1].name === currentlyPlaying ? (
            currentPlayers[1].waitingRoll ? (
              currentlyPlaying === userDetails.name ? (
                <button className="rollBtn" onClick={rollDice}>
                  Roll Dice
                </button>
              ) : (
                <button className="rollBtn" onClick={rollDice}>
                  Waiting...
                </button>
              )
            ) : currentlyPlaying === currentPlayers[1].name ? (
              <section className="userDetailsHolderDice">
                {currentPlayers[1].dice.map((v, index) => (
                  <Die
                    key={currentPlayers[1].name + v + index}
                    value={v}
                    index={index}
                    selected={index === selecteDice ? true : false}
                    setSelectedDice={setSelectedDice}
                    resolved={currentPlayers[1].diceResolved[index]}
                    colourCode={currentPlayers[0].colourCode}
                  />
                ))}
              </section>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </section>

        <section className="userDetailsHolder">
          <section className="userDetailsHolderInfo">
            <p>{currentPlayers[2].name}</p>
            <img
              onError={(e) => addDefaultSrc(e)}
              src={
                currentPlayers[2].name[0] === "@"
                  ? "https://localpocketimagesave.s3.eu-west-1.amazonaws.com/modes/bot-profile.png"
                  : currentPlayers[2].avatar
              }
              alt="player"
            />
          </section>
          {currentPlayers[2].name === currentlyPlaying ? (
            currentPlayers[2].waitingRoll ? (
              currentlyPlaying === userDetails.name ? (
                <button className="rollBtn" onClick={rollDice}>
                  Roll Dice
                </button>
              ) : (
                <button className="rollBtn" onClick={rollDice}>
                  Waiting...
                </button>
              )
            ) : currentlyPlaying === currentPlayers[2].name ? (
              <section className="userDetailsHolderDice">
                {currentPlayers[2].dice.map((v, index) => (
                  <Die
                    key={currentPlayers[2].name + v + index}
                    value={v}
                    index={index}
                    selected={index === selecteDice ? true : false}
                    setSelectedDice={setSelectedDice}
                    resolved={currentPlayers[2].diceResolved[index]}
                    colourCode={currentPlayers[0].colourCode}
                  />
                ))}
              </section>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </section>
      </section>
    </div>
  );
};

export default BoardView;
