import React from "react";
import { motion } from "framer-motion";
import styles from "./roomPassword.module.scss";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

interface Props {
  socket: any;
  selectedRoomDetails: any;
  userDetails: any;
  joinRoom: any;
  setUserDetails: any;
  selectedRoomType: any;
  setSelectedRoomType: any;
}
const OwnRoom: React.FC<Props> = ({
  joinRoom,
  selectedRoomDetails,
  setSelectedRoomType,
}) => {
  const [passwordValue, setPasswordValue] = React.useState("");

  const handleNameValueChange = (value: any) => {
    setPasswordValue(value.target.value);
  };

  return (
    <section className={styles.settings}>
      <motion.section
        className={styles.ownroomDashboard}
        initial={{ y: -1000 }}
        animate={{ y: 0 }}
        transition={{
          when: "BeforeChildren",
        }}
      >
        <section className={styles.roomSettings}>
          <section className={styles.navButtons}>
            <button
              type="button"
              onClick={() => setSelectedRoomType(0)}
              className={styles.settingsClose}
            >
              <span>
                <HighlightOffIcon />
              </span>
            </button>
          </section>
          <p className={styles.roomSettingsHeading}>Password</p>
          <input
            className={styles.roomSettingsName}
            placeholder={"Password"}
            type="password"
            value={passwordValue}
            onChange={handleNameValueChange}
          />

          <button
            type="button"
            onClick={() =>
              joinRoom(selectedRoomDetails.roomOwner, passwordValue)
            }
            className={styles.settingsSubmit}
          >
            <span>Join</span>
          </button>
        </section>
      </motion.section>
    </section>
  );
};

export default OwnRoom;
