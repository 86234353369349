import React, { useEffect } from "react";

import WinnerScreen from "./WinnerScreen";
import LeaderboardScreen from "./LeaderboardScreen";
import SelectionScreen from "./SelectionScreen";
import GameRoundScreen from "./GameRoundScreen";
// import GameScreen

import styles from "./triviaScreen.module.scss";

import pageBackground from "../../../img/game-mode/trivia/main-trivia-background.png";

const Trivia = ({ userDetails, socket }) => {
  // Quesion Data
  const [questionData, setQuestionData] = React.useState({
    question: "",
    options: [],
    category: "",
    type: "",
    effect: "",
    difficulty: "",
    roundData: {},
  });

  // Rounds Data
  const [counter, setCounter] = React.useState(0);
  // const [round, setRound] = React.useState(0);
  const [manualValue, setManualValue] = React.useState("");

  const [answer, setAnswer] = React.useState("");
  const [answered, setAnswered] = React.useState(false);
  const [selectedAnswer, setSelectedAnswer] = React.useState("");
  const [readyMessage, setReadyMessage] = React.useState("");

  const [currentScreen, setCurrentScreen] = React.useState(-1);

  // Scoring
  // const [showVictory, setVictory] = React.useState([]);
  const [leaderboard, setLeaderboard] = React.useState([]);
  const [correctGuessers, setCorrectGuessers] = React.useState([]);

  useEffect(() => {
    socket.on("counter", function (counter) {
      setCounter(counter);
    });

    socket.on("leaderboard", function (data) {
      setLeaderboard(data.leaderboard);
      setCorrectGuessers(data.correctGuessers);

      const userCheck = data.correctGuessers.filter(
        (user) => user.name === userDetails.name
      );

      // [name: ]

      if (userCheck[0]) {
        if (userCheck[0].name === userDetails.name) {
          setCurrentScreen(1);
        }
      } else {
        setCurrentScreen(2);
      }
    });

    socket.on("categoryMode", function (mode) {
      setCurrentScreen(mode);
    });

    socket.on("screenMessage", function (message) {
      console.log("TEST");
      console.log(message);
      setReadyMessage(message);
    });

    socket.on("updateRoom", function (rooms) {
      // setRound(rooms[1]);
    });

    socket.on("questionData", function (data) {
      setReadyMessage("");
      setManualValue("");
      setCurrentScreen(0);

      setAnswer("");
      setAnswered(false);
      setSelectedAnswer("");

      setQuestionData({
        question: data.question,
        options: data.answerOptions,
        category: data.category,
        type: data.type,
        effect: data.effect,
        difficulty: data.difficulty,
        roundData: data.roundData,
      });
    });

    socket.on("answer", function (answer) {
      setAnswer(answer);
      setSelectedAnswer("");
    });

    socket.on("updateRound", function (round) {
      // setRound(round);
    });

    socket.on("screenMessageWinner", (data) => {
      // Data.word
      setAlerts(
        <WinnerScreen
          imageURL={data.image}
          Message={data.message}
          color={"rgb(22, 184, 30)"}
          throwConfetti={true}
        />
      );

      setTimeout(() => {
        setAlerts("");
      }, 5000);
    });

    socket.on("screenMessageLeaderboard", (data) => {
      setQuestionData({
        question: "",
        options: [],
        category: "",
        type: "",
        effect: "",
        difficulty: "",
        roundData: {},
      });

      setAnswer("");

      // setVictory(data);

      setAlerts(
        <LeaderboardScreen
          leaderboard={data}
          color={"rgb(243, 166, 0)"}
          throwConfetti={true}
        />
      );

      setTimeout(() => {
        setAlerts("");
        // setVictory([]);
      }, 5000);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [alerts, setAlerts] = React.useState();

  return (
    <>
      <section
        id="container"
        // src={pageBackground}
        style={{
          // background: "white",
          backgroundImage: `url(${pageBackground})`,
          opacity: "0.2",
        }}
        className={styles.menuBackground}
      />

      <section className={styles.triviaDashboard}>
        {window.scrollTo(0, 0)}

        {currentScreen === -1 ? (
          <SelectionScreen socket={socket} readyMessage={readyMessage} />
        ) : currentScreen === 0 ? (
          <GameRoundScreen
            socket={socket}
            questionData={questionData}
            selectedAnswer={selectedAnswer}
            manualValue={manualValue}
            answered={answered}
            setManualValue={setManualValue}
            setAnswered={setAnswered}
            setSelectedAnswer={setSelectedAnswer}
            counter={counter}
            userDetails={userDetails}
            leaderboard={leaderboard}
          />
        ) : currentScreen === 1 ? (
          <section className={styles.victoryScreen}>
            <p className={styles.victoryScreenHeading}>{answer}</p>
            <p className={styles.victoryScreenScore}>
              {leaderboard
                .filter((e) => e.name === userDetails.name)
                .map((e) => e.score)}
            </p>

            <section>
              <section className={styles.victoryScreenLeaderboard}>
                <p>Name</p>
                <p>Score</p>
                <p>Change</p>
                <p>Seconds</p>
              </section>
              {leaderboard.map((user) => (
                <section className={styles.victoryScreenLeaderboard}>
                  <p>{user.name}</p>
                  <p>{user.score}</p>

                  {correctGuessers.filter((e) => e.name === user.name) !==
                    [] ? (
                    <p>
                      {correctGuessers
                        .filter((e) => e.name === user.name)
                        .map((e) => {
                          return "+ " + (100 + e.time * 2);
                        })}
                    </p>
                  ) : (
                    <p>0</p>
                  )}

                  {correctGuessers.filter((e) => e.name === user.name) !==
                    [] ? (
                    <p>
                      {correctGuessers
                        .filter((e) => e.name === user.name)
                        .map((e) => {
                          return 10 - e.time;
                        })}
                    </p>
                  ) : (
                    <p>0</p>
                  )}
                </section>
              ))}
            </section>
          </section>
        ) : (
          <section className={styles.lossScreen}>
            <p className={styles.lossScreenHeading}>{answer}</p>
            <p className={styles.lossScreenScore}>
              {leaderboard
                .filter((e) => e.name === userDetails.name)
                .map((e) => e.score)}
            </p>

            <section>
              <section className={styles.lossScreenLeaderboard}>
                <p style={{ fontWeight: 900 }}>Name</p>
                <p style={{ fontWeight: 900 }}>Score</p>
                <p style={{ fontWeight: 900 }}>Change</p>
                <p style={{ fontWeight: 900 }}>Seconds</p>
              </section>
              {leaderboard.map((user) => (
                <section className={styles.lossScreenLeaderboard}>
                  <p>{user.name}</p>
                  <p>{user.score}</p>

                  {correctGuessers.filter((e) => e.name === user.name) !==
                    [] ? (
                    <p>
                      {correctGuessers
                        .filter((e) => e.name === user.name)
                        .map((e) => {
                          return "+ " + (100 + e.time * 2);
                        })}
                    </p>
                  ) : (
                    <p>0</p>
                  )}

                  {correctGuessers.filter((e) => e.name === user.name) !==
                    [] ? (
                    <p>
                      {correctGuessers
                        .filter((e) => e.name === user.name)
                        .map((e) => {
                          return 10 - e.time;
                        })}
                    </p>
                  ) : (
                    <p>0</p>
                  )}
                </section>
              ))}
            </section>
          </section>
        )}
      </section>
      {alerts}
    </>
  );
};

Trivia.defaultProps = {
  width: "1000",
  height: "500",
};

export default Trivia;
