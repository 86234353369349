import React from "react";
import { motion } from "framer-motion";

import man1 from "../../../../img/game-mode/swaps/Simple-Icons/man1.png";
import man2 from "../../../../img/game-mode/swaps/Simple-Icons/man2.png";
import man3 from "../../../../img/game-mode/swaps/Simple-Icons/man3.png";
import man4 from "../../../../img/game-mode/swaps/Simple-Icons/man4.png";
import man5 from "../../../../img/game-mode/swaps/Simple-Icons/man5.png";
import man6 from "../../../../img/game-mode/swaps/Simple-Icons/man6.png";
import EmptyBlock from "../../../../img/game-mode/swaps/Simple-Icons/empty-block.png";

const getImage = (colourCode) => {
  switch (colourCode) {
    case "red":
      return man5;

    case "yellow":
      return man4;

    case "orange":
      return man6;

    case "purple":
      return man3;

    case "green":
      return man1;

    case "blue":
      return man2;

    default:
      return EmptyBlock;
  }
};

const Cell = ({
  index,
  selectedCells,
  setSelectedCells,
  colour,
  setSelected,
  selected,
  ability,

  firstSelected,
  setFirstSelecte,
}) => {
  return (
    <motion.img
      onTapStart={() => {
        setFirstSelecte(index);
      }}
      onTapCancel={() => {
        setSelectedCells([index, selected]);

        // setSelected(index);
      }}
      onMouseEnter={() => {
        setSelected(index);
      }}
      alt=""
      src={getImage(colour)}
      onClick={() => {
        // console.log(selectedCells[0] === index);
        // console.log(selectedCells[0]);
        // console.log(index);

        setSelectedCells((e) => [...e, index]);
      }}
      style={{
        // background: colour,

        opacity:
          selectedCells[0] && selectedCells[0].id === index.id
            ? "0.2"
            : firstSelected.id === index.id
            ? "0.2"
            : "1",
        border:
          ability === "Bomb"
            ? "3px solid Red"
            : ability === "DoublePoints"
            ? "3px solid Cyan"
            : ability === "MoreTime"
            ? "3px solid Lime"
            : "None",
      }}
      className={`swap-cell ${
        selectedCells[0] && selectedCells[0].id === index.id
          ? "bigShake"
          : firstSelected.id === index.id
          ? "bigShake"
          : ability === "Bomb"
          ? "mine"
          : ""
      }`}
    />
  );
};

export default Cell;
