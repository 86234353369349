import React from "react";
import Emoji from "react-emoji-render";
import SendIcon from "@material-ui/icons/Send";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import "./privateMessages.scss";
import logo from "../../../../img/general/LogoSpaced.png";

const PrivateMessages = ({
  socket,
  chatMessageList,
  userDetails,
  selectedUser,
  setSelectedUser
}) => {
  const [chatTextField, setChatTextField] = React.useState("");
  const inputEl = React.useRef(null);

  const [muteList, setMuteList] = React.useState([]);

  const handleChatFieldChange = (value) => {
    setChatTextField(value.target.value);
  };

  const messagesEndRef = React.createRef();

  const scrollToBottom = () => {
    if (selectedUser === "") {
      return;
    }
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };

  React.useEffect(() => {
    scrollToBottom()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessageList, selectedUser]);

  //Sending message
  const sendMessage = () => {
    const msgParts = chatTextField.split(" ");

    // const bucket = path_parts.pop(0);
    if (msgParts[0] === "/mute") {
      setMuteList((e) => [...e, msgParts[1]]);
      setChatTextField("");
      return;
    }
    if (msgParts[0] === "/unmute") {
      setMuteList((e) => e.filter((e) => e !== msgParts[1]));
      setChatTextField("");
      return;
    }

    if (chatTextField !== "") {
      socket.emit("createMessage", {
        text: `/msg ${selectedUser} ${chatTextField}`,
      }, (res) => {
        console.log(res)
      });
    }

    setChatTextField("");
  };

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  const names = [];

  return (
    <section className="private-message-main">
      {selectedUser === "" ? (
        <section className="private-message-main-messages">
          <p className="private-message-main-messages__heading">Messages</p>
          {chatMessageList
            .filter((message) => message.type === "Private")
            .filter((message) => {

              if (message.from === userDetails.name) {
                if (names.includes(message.to)) {

                  return false;
                } else {
                  names.push(message.to);
                  return true;
                }
              } else {
                if (names.includes(message.from)) {

                  return false;
                } else {
                  names.push(message.from);
                  return true;
                }
              }
            })
            .map((e) => {
              return (
                <section
                  className="private-message-main-messages__cards"
                  onClick={() => setSelectedUser(e.from === userDetails.name ? e.to : e.from)}
                >
                  <img
                    className="private-message-main-messages__cards__img"
                    src={e.from === userDetails.name ? e.toAvatar : e.fromAvatar}
                    alt={e.from}
                  />
                  <p className="private-message-main-messages__cards__name">
                    {e.from === userDetails.name ? e.to : e.from}
                  </p>
                  <ArrowRightIcon />
                </section>
              );
            })}
        </section>
      ) : (
        <section className="private-message-chat">
          <section className="private-message-chat__heading">
            <ArrowLeftIcon onClick={() => setSelectedUser("")} />
            <p>{selectedUser}</p>
          </section>
          <ul>
            {chatMessageList
              .filter((message) => message.type === "Private")
              .filter(
                (message) =>
                  (message.from === selectedUser &&
                    message.to === userDetails.name) ||
                  (message.to === selectedUser &&
                    message.from === userDetails.name)
              )
              .map((e, i) => {
                if (e.from === "Server") {
                  return (
                    <li key={e.from + i} className="private-message-chat__box">
                      <img
                        onError={(e) => addDefaultSrc(e)}
                        className="chat-image"
                        src={logo}
                        alt="player"
                      />
                      <section className="player-message">
                        <p className="player-message__server">{e.from}</p>
                        <p className="player-message__serverm">{e.message}</p>
                      </section>
                    </li>
                  );
                } else {
                  if (!muteList.includes(e.from)) {
                    return (
                      <li key={e.from + i} className="private-message-chat__box">
                        <img
                          onError={(e) => addDefaultSrc(e)}
                          className="chat-image"
                          src={e.fromAvatar}
                          alt="player"
                        />
                        <section className="player-message">
                          <p
                            className="player-message__sender"
                          >
                            {e.from}
                          </p>
                          <p className="player-message__message">
                            <Emoji text={e.message} />
                          </p>
                        </section>
                      </li>
                    );
                  }
                  else {
                    return (<></>)
                  }
                }
              })}
            <div ref={messagesEndRef} />
          </ul>
          <footer className="private-message-chat__footer">
            <input
              ref={inputEl}
              className="message_input"
              placeholder="Type a message"
              value={chatTextField}
              onChange={handleChatFieldChange}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  sendMessage();
                  ev.preventDefault();
                }
              }}
            />
            <button onClick={() => sendMessage()}>
              <SendIcon />
            </button>
          </footer>
        </section>
      )}
    </section>
  );
};
export default PrivateMessages;
