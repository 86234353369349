import React from "react";
import { motion } from "framer-motion";

import styles from "./gameRoundScreen.module.scss";
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const GameRoundScreen = ({ userDetails, questionData, leaderboard, selectedAnswer, manualValue, answered, setManualValue, setAnswered, setSelectedAnswer, socket, counter }) => {
  const handleAnsweredChange = (value) => {
    setAnswered(value);
  };

  const handleSelectedAnswerChange = (value) => {
    setSelectedAnswer(value);
  };

  const setManualAnswerValue = (value) => {
    setManualValue(value.target.value);
  };

  return (
    <section className={styles.triviaDataHolder}>
      {/* <canvas ref={canvasRef} height={height} width={width} /> */}

      {questionData.category !== "" && questionData.difficulty !== "" ? (
        <section className={styles.questionDetails}>
          <section
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "black",
              width: 400,
            }}
          />

          <motion.p
            style={{ background: "#74b9ff" }}
            className={styles.questionDetailsBoxes}
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.2 }}
          >
            {questionData.category}
          </motion.p>
          <motion.p
            style={{ background: "#55efc4" }}
            className={styles.questionDetailsBoxes}
            initial={{ x: 1000 }}
            animate={{ x: 0 }}
            transition={{ delay: 0.3 }}
          >
            {questionData.difficulty.toUpperCase()}
          </motion.p>
        </section>
      ) : (
        <></>
      )}

      <section className={styles.nextPerson}>
        <section className={styles.nextPersonBar}><span style={{ height: leaderboard.length > 0 ? `${leaderboard.filter(user => user.name === userDetails.name)[0].score / leaderboard[0].score * 100}%` : "0%" }}></span></section>
        {leaderboard.length > 0 ? (
          <section className={styles.nextPersonUserDetails}>
            <img src={leaderboard[0].avatar} alt="avatar" />
            <p>{leaderboard[0].name}</p>
          </section>)
          : <></>}
      </section>

      <h1 style={{ color: counter <= 3 ? "#ff7675" : "White", fontSize: "2rem", fontWeight: "900" }}>{counter}</h1>


      <p className={styles.questionCounter} style={{ color: "#fcfcfc" }}>Quesiton <span style={{ fontWeight: "900", paddingLeft: "5px", paddingRight: "5px" }}>{questionData.roundData.currentRound}</span>/<span style={{ color: "#ffffffb0", paddingLeft: "5px" }}>{questionData.roundData.maxRound}</span></p>
      <section className={styles.dotHolder}>
        {Array.apply(0, Array(questionData.roundData.maxRound)).map(function (x, i) {
          return <span style={{ background: questionData.roundData.currentRound <= i ? "#2d557e75" : "#74b9ff" }} className={styles.dot}></span>
        })}
      </section>

      {
        questionData.effect === "tiles" ? (
          <section className={styles.tileHolder}>
            {questionData.question.split("").map((letter) => (
              <p
                style={{ background: letter === " " ? "White" : "#ffffff", color: "#d63031", minWidth: letter === " " ? "10px" : "20px" }}
                className={styles.questionTiles}
              >
                {letter}
              </p>
            ))}
          </section>
        ) : (
          <p className={styles.question}>{questionData.question}</p>
        )
      }
      {
        questionData.type !== "manual" ? (
          <section className={styles.options}>
            {questionData.options.map((option) =>
              selectedAnswer ? (
                selectedAnswer === option ? (
                  <button
                    initial={{ y: -1000 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.2, when: "BeforeChildren" }}
                    className={styles.optionsSelectButton}
                    onClick={(e) => {
                      if (!answered) {
                        socket.emit("gameData", {
                          type: "answer",
                          selectedOption: option,
                        }, (res) => {

                        });
                        handleSelectedAnswerChange(option);
                        handleAnsweredChange(true);
                      }
                    }}
                  >
                    {option}
                  </button>
                ) : (
                  <button
                    style={{ opacity: 0.2 }}
                    initial={{ y: -1000 }}
                    animate={{ y: 0 }}
                    transition={{ delay: 0.2, when: "BeforeChildren" }}
                    className={styles.optionsSelectButton}
                    onClick={(e) => {
                      if (!answered) {
                        socket.emit("gameData", {
                          type: "answer",
                          selectedOption: option,
                        }, (res) => {

                        });
                        handleSelectedAnswerChange(option);
                        handleAnsweredChange(true);
                      }
                    }}
                  >
                    {option}
                  </button>
                )
              ) : (
                <motion.button
                  className={styles.optionsSelectButton}
                  initial={{ y: -1000 }}
                  animate={{ y: 0 }}
                  transition={{ delay: 0.1, when: "BeforeChildren" }}
                  whileHover={{
                    opacity: 0.5
                  }}
                  onClick={(e) => {
                    if (!answered) {
                      socket.emit("gameData", {
                        type: "answer",
                        selectedOption: option,
                      }, (res) => {

                      });
                      handleSelectedAnswerChange(option);
                      handleAnsweredChange(true);
                    }
                  }}
                >
                  {option}
                </motion.button>
              )
            )}
          </section>
        ) : (
          <section className={styles.triviaManualInput}>
            <p>Enter your answer below</p>
            <section
              className={styles.triviaManualInputHolder}
              style={{ opacity: answered ? "0.1" : "1" }}
            >
              <input
                className={styles.triviaAnswerInput}
                placeholder={"Answer here"}
                value={manualValue}
                onChange={setManualAnswerValue}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    socket.emit("gameData", {
                      type: "answer",
                      selectedOption: manualValue,
                    }, (res) => {

                    });

                    handleSelectedAnswerChange(manualValue);
                    handleAnsweredChange(true);
                    ev.preventDefault();
                  }
                }}
              />
              <button
                type="button"
                onClick={() => {
                  if (!answered && manualValue !== "") {
                    socket.emit("gameData", {
                      type: "answer",
                      selectedOption: manualValue,
                    }, (res) => {

                    });
                    handleSelectedAnswerChange(manualValue);
                    handleAnsweredChange(true);
                  }
                }}
                className={styles.triviaAnswerSubmit}
              >
                <LabelImportantIcon />
              </button>
            </section>
          </section>
        )
      }
    </section >
  );
};

export default GameRoundScreen;
