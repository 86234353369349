import React from "react";
import SettingsIcon from '@material-ui/icons/Settings';
import "./roomOptions.scss";
import { toast } from 'react-toastify';

const MenuProfile = ({ socket, setSelectedRoomType, userDetails }) => {


  // const [room, setErrorMessage] = React.useState("");
  // const [selectedRoomType, setSelectedRoomType] = React.useState(0);
  const [roomDetails, setRoomDetails] = React.useState({});

  const [roomPlayerList, setRoomPlayerList] = React.useState([]);

  const [roomName, setRoomName] = React.useState({});

  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    socket.emit("getOwnRoom", (response) => {
      const data = response.data;

      console.log(response);

      if (data !== {}) {
        setRoomDetails(data);
      } else {
        // setErrorMessage("Not your room");
      }

      if (data.roomOwner) {
        // Room is active
        // setTitleName(data.configSettings.title);
      } else {
        // Room is not active
      }
    });

    socket.emit("updateUserList");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    socket.on("updateUserList", (roomDetails) => {
      // setRoomPlayerList([]);
      const { roomName, users, avatar } = roomDetails;
      setRoomPlayerList(users);
      setRoomName({ name: roomName, avatar: avatar });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectProfile = (name) => {
    socket.emit("getUserProfile", { name: name }, (response) => {

      if (response.status === true) {
      } else {
      }
    });
  };

  const addDefaultSrc = (ev) => {
    ev.target.src =
      "https://localpocketimagesave.s3-eu-west-1.amazonaws.com/Logo.png";
  };

  const [addrtype, setAddrtype] = React.useState(["Choose a new mode...", "Room Lobby", "Draw Together", "Trivia", "Oh-No", "NumberSwap", "Match Saga"])
  const Add = addrtype.map(Add => Add
  )
  const handleAddrTypeChange = (e) => {
    socket.emit("changeGameMode", { mode: e.target.value }, (res) => {

    });
  }

  return (
    <section className="room-options-section">
      {roomName !== {} ? (
        <section className="room-options-section__room">
          <img
            onError={(e) => addDefaultSrc(e)}
            className="player-image"
            src={roomName.avatar}
            alt="player"
          />
          <p> {roomName.name}'s Room</p>
        </section>
      ) : (
        <></>
      )}

      {roomDetails.roomOwner ? (
        <select
          onChange={e => handleAddrTypeChange(e)}
          className="browser-default custom-select" >
          {
            Add.map((address, key) => <option key={key} value={key}>{address}</option>)
          }
        </select >
      ) : (
        <></>
      )}


      {roomDetails.roomOwner ? (
        <button
          className="manageRoom"
          onClick={() => setSelectedRoomType(-2)}
        >
          <SettingsIcon />
        </button>
      ) : (
        <></>
      )}

      <p className="room-options-sep">Currently in the room</p>
      <section className="room-options-section__list">
        {roomPlayerList.map((player) => {
          return (
            <section
              key={player.name}
              onClick={(e) => {

                if (userDetails.name === player.name) {
                  toast.warning("This is your own profile", {
                    position: "top-left",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                else {
                  selectProfile(player.name);
                }
              }}
              className="room-options-section__list__card"
            >
              <img
                onError={(e) => addDefaultSrc(e)}
                key={player.name}
                src={player.avatar}
                alt="player"
              />
              <p>{player.name}</p>
            </section>
          );
        })}
      </section>
    </section>
  );
};
export default MenuProfile;
