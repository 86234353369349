import React from "react";
import "./matchSaga.scss"

import BoardViewMultiplayer from "./components/multiplayerBoard"

const MatchSaga = ({ socket, userDetails }) => {

  const [currentPlayers, setCurrentPlayers] = React.useState([

  ]);


  React.useEffect(() => {
    socket.emit("gameData", {
      type: "getCurrentUserList",
    }, (res) => {

    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    socket.on("getCurrentUserList", function (playerList) {
      setCurrentPlayers(playerList);

      console.log(playerList)
    });

    return () => {
      socket.off("getCurrentUserList")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const sendNewSoloScore = (score) => {
    socket.emit("gameData", {
      type: "addSoloPlayerScore",
      soloScore: score
    }, (res) => {

    });
  }

  return (
    <section className="swap-board-main">
      <BoardViewMultiplayer socket={socket} currentPlayers={currentPlayers} sendNewSoloScore={sendNewSoloScore} />
    </section>
  );
};

export default MatchSaga;
