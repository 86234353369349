import React from "react";
import "./wordChoice.scss";

const WordChoice = ({ socket, random3Words, setRandom3Words }) => {

  return (
    <section className="word-box">
      <p>Choose a Word</p>
      <div id="container"></div>
      <section className="word-box__join">
        {random3Words.map((word) => (
          <button
            className="word-button"
            key={word}
            onClick={() => {
              socket.emit("gameData", { type: "chooseAWord", value: word }, (res) => {

              });
              setRandom3Words([]);
            }}
          >
            {word}
          </button>
        ))}
      </section>
    </section>
  );
};

export default WordChoice;
