import React from "react";
import { motion } from "framer-motion";

import man1 from "../../../../img/game-mode/swaps/Simple-Icons/man1.png";
import man2 from "../../../../img/game-mode/swaps/Simple-Icons/man2.png";
import man3 from "../../../../img/game-mode/swaps/Simple-Icons/man3.png";
import man4 from "../../../../img/game-mode/swaps/Simple-Icons/man4.png";
import man5 from "../../../../img/game-mode/swaps/Simple-Icons/man5.png";
import man6 from "../../../../img/game-mode/swaps/Simple-Icons/man6.png";
import EmptyBlock from "../../../../img/game-mode/swaps/Simple-Icons/empty-block.png";

const getImage = (colourCode) => {
  switch (colourCode) {
    case "red":
      return man5;

    case "yellow":
      return man4;

    case "orange":
      return man6;

    case "purple":
      return man3;

    case "green":
      return man1;

    case "blue":
      return man2;

    default:
      return EmptyBlock;
  }
};

const Cell = ({
  index,
  selectedCells,
  setSelectedCells,
  colour,
  setSelected,
  selected,
  ability,
  col,
  firstSelected,
  setFirstSelecte,
  onSelectPieceTile,
}) => {
  return (
    <motion.section
      // onTapStart={() => {
      //   setFirstSelecte(index);
      // }}
      // onTapCancel={() => {
      //   setSelectedCells([index, selected]);

      //   // setSelected(index);
      // }}
      // onMouseEnter={() => {
      //   setSelected(index);
      // }}
      alt=""
      // src=""
      onClick={() => {
        // console.log(selectedCells[0] === index);
        // console.log(selectedCells[0]);
        // console.log(index);

        // setSelectedCells((e) => [...e, index]);

        onSelectPieceTile(index);
      }}
      style={{
        background: col.groundColour,
        // border: col.border ? "1px solid black" : "none",
        border: "none",
        backgroundImage:
          col.image !== ""
            ? `url(${col.image})`
            : // : col.groundColour === "white"
              // ? `url(https://cdna.artstation.com/p/assets/images/images/016/144/870/large/cgshare-book-ground-floor-003.jpg?1551084649)`
              "none",
        backgroundSize: "cover",
      }}
      className={`ludo-board-cell`}
    >
      {col.currentPieces.map((e) => {
        if (col.currentPieces.length > 1) {
          return (
            <section
              style={{ backgroundColor: e.pieceColour }}
              className={`ludo-board-cell__tiles`}
            />
          );
        } else {
          return (
            <section
              style={{ backgroundColor: e.pieceColour }}
              className={`ludo-board-cell__tile`}
            />
          );
        }
      })}
    </motion.section>
  );
};

export default Cell;
